/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var MaintCheckSheetLogs = (function() {
  let ert_types = undefined;
  let ert_subtypes = undefined;
  let ert_cat_items = undefined;
  let ert_categories = undefined;
  let theErt = undefined;
  MaintCheckSheetLogs = class MaintCheckSheetLogs {
    static initClass() {
  
      //########################################################################################################
      // Class variables
      ert_types = [];
      ert_subtypes = [];
      ert_cat_items = [];
      ert_categories = [];
      theErt = null;
    }

    //########################################################################################################
    // always called
    init() {}
      //window.common.debug('Init called from window.maint_check_sheet_logs')
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/edit
    edit() {}
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {}
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new_ert() {
      $('#ert_ert_type_id').on('change', this.changed_ert_type);
      $('#ert_ert_category_id').on('change', this.changed_ert_category);
      $('#data-table').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        "aoColumns": [
          { "bSortable": false }, { "bSortable": false }
        ]
      });

      if (window.maint_check_sheet_logs.theErt.ert_type_id != null) {
        //window.common.debug( "Update ERT Settings, ERT Type selected" )
        let laClickedElem = $('#ert_ert_type_id').prop("selectedIndex");
        //window.common.debug( laClickedElem )
        //window.common.debug( window.maint_check_sheet_logs.ert_types[laClickedElem-1] )
        window.maint_check_sheet_logs.set_ert_form( laClickedElem-1 );
        $('#ert_ert_subtype_id').val( window.maint_check_sheet_logs.theErt.ert_subtype_id );
        $('#ert_ert_category_id').val( window.maint_check_sheet_logs.theErt.ert_category_id );
        laClickedElem = $('#ert_ert_category_id').prop("value");
        window.maint_check_sheet_logs.set_ert_category( laClickedElem );
      } else {
        //window.common.debug( "No need to update ERT Settings, ERT Type not selected" )
        $('#ert_vehicle_header').hide();
        $('#ert_vehicle_oos_header').hide();
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').hide();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();
        $('#ert_comment_header').hide();
      }
    }

    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    show() {
      var url = window.location.pathname;
      var id = url.substring(url.lastIndexOf('/') + 1);
      // alert(id);
      var the_path = '/manager/maint_check_sheet_logs/get_erts/'

      $('#data-table-erts').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          { data: 'type' },
          { data: 'subtype' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'assigned_to' },
          { data: 'status' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "All of the ERTs that were created for this sheet have been closed."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#open_authored_erts-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    facility_erts() {
      return $('#data-table-facility_erts').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_data(anErt) {
      //window.common.debug( anErt )
      return window.maint_check_sheet_logs.theErt = anErt;
    }
    //########################################################################################################

    //########################################################################################################
    add_ert_types(anErtTypes) {
      return window.maint_check_sheet_logs.ert_types = anErtTypes;
    }
      //window.common.debug(window.maint_check_sheet_logs.ert_types[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_subtypes(anErtSubtypes) {
      return window.maint_check_sheet_logs.ert_subtypes = anErtSubtypes;
    }
      //window.common.debug(window.maint_check_sheet_logs.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_categories(anErtCategories) {
      return window.maint_check_sheet_logs.ert_categories = anErtCategories;
    }
      //window.common.debug(window.maint_check_sheet_logs.ert_categories[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_cat_items(anErtCatItems) {
      return window.maint_check_sheet_logs.ert_cat_items = anErtCatItems;
    }
      //window.common.debug(window.maint_check_sheet_logs.ert_cat_items[0])
    //########################################################################################################

    //########################################################################################################
    changed_ert_type(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //window.common.debug( window.maint_check_sheet_logs.ert_types[laClickedElem-1] )
      return window.maint_check_sheet_logs.set_ert_form( laClickedElem-1 );
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_form( anErtType ) {
      const lrErtType = window.maint_check_sheet_logs.ert_types[ anErtType ];

      // Display the vehicles if the ERT type uses them
      if (lrErtType.has_vehicles) {
        $('#ert_vehicle_header').show();
      } else {
        $('#ert_vehicle_header').hide();
      }

      if ( lrErtType.has_vehicles && !lrErtType.has_subtypes  && !lrErtType.has_actions && !lrErtType.has_categories ) {
        $('#ert_vehicle_oos_header').show();
      } else {
        $('#ert_vehicle_oos_header').hide();
      }

      if (lrErtType.comment) {
        $('#ert_comment_header').show();
        $('#ert_comment').html(lrErtType.comment);
      } else {
        $('#ert_comment_header').hide();
      }

      // Display the ERT Subtypes if the ERT type uses them
      if (lrErtType.has_subtypes) {
        $('#ert_sub_types_header').show();
        $('#ert_equipment_oos_header').show();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();

        // Clear and rebuild the sub_type option list based on the selected ERT type
        // Of course we need to add a default ERT Subtype too.
        $("#ert_ert_subtype_id").empty();
        $('#ert_ert_subtype_id').append($('<option>', {
          value: 0,
          text : "Select an ERT Subtype"
        }));

        return (() => {
          const result = [];
          for (let element of Array.from(window.maint_check_sheet_logs.ert_subtypes)) {
          // Only add the ERT subtype if it belongs to the selected ERT type
            if (lrErtType._id === element.ert_type_id) {
              result.push($('#ert_ert_subtype_id').append($('<option>', {
                value: element._id,
                text : element.name
              })));
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      } else if (lrErtType.has_categories) {
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').show();
        $('#ert_categories_header').show();
        $('#ert_category_items_header').show();

        $('#ert_category_item_tracking_ert_category_id').prop('selectedIndex', 0);
        $('#ert_ert_category_id').empty();
        $('#cat_items_tbody').empty();
        $('#ert_ert_category_id').append($('<option>', {
          value: 0,
          text : "Please Select a Category"
        }));

        return (() => {
          const result1 = [];
          for (let element of Array.from(window.maint_check_sheet_logs.ert_categories)) {
          // Only add the ERT Categories if it belongs to the selected ERT type
            if (lrErtType._id === element.ert_type_id) {
              result1.push($('#ert_ert_category_id').append($('<option>', {
                value: element._id,
                text : element.name
              })));
            } else {
              result1.push(undefined);
            }
          }
          return result1;
        })();

      } else {
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').hide();
        $('#ert_categories_header').hide();
        return $('#ert_category_items_header').hide();
      }
    }
    //########################################################################################################

    //########################################################################################################
    changed_ert_category(event) {
      const laClickedElem = $(event.target).prop("value");
      return window.maint_check_sheet_logs.set_ert_category( laClickedElem );
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_category( anCatId ) {
      //window.common.debug( window.maint_check_sheet_logs.ert_cat_items )
      const laClickedElem = anCatId;
      let lnItemCount = 0;
      $('#cat_items_tbody').empty();
      for (let element of Array.from(window.maint_check_sheet_logs.ert_cat_items)) {
        // Only add the ERT Items if it belongs to the selected ERT Type
        if (laClickedElem === element.ert_category_id) {
          const lsItemId = '<input type="hidden" id="ert_item_id'+lnItemCount+'" name="ert_item_id'+lnItemCount+'" value="'+element._id+'"';
          const lsItem = "<td>"+element.name+lsItemId+"</td>";
          const lsQuantity = '<td><input id="ert_item'+lnItemCount+'" name="ert_item'+lnItemCount+'" size="5" type="text"></td>';
          $('#cat_items_tbody').append('<tr>'+lsItem+lsQuantity+'</tr>');
          lnItemCount = lnItemCount + 1;
        }
      }

      return $('#ert_cat_item_count').prop( 'value', lnItemCount );
    }
    //########################################################################################################

    //########################################################################################################
    show_hide_comments(the_sub_item, the_ims_instance) {
      $("#task_log_div_"+the_sub_item).hide();
      if ( $("#comments_div_"+the_sub_item).is(':visible') ) {
          $("#comments_div_"+the_sub_item).hide();
      } else {
          $("#comments_div_"+the_sub_item).show();
          var the_url = '/manager/maint_check_sheet_task_logs/' + the_sub_item + '/get_task_comments'
          $.get(the_url);
      }
    }
    //########################################################################################################

    //########################################################################################################
    show_hide_erts(the_sub_item, the_ims_instance) {
      $("#comments_div_"+the_sub_item).hide();
      if ( $("#task_log_div_"+the_sub_item).is(':visible') ) {
          $("#task_log_div_"+the_sub_item).hide();
      } else {
        $("#task_log_div_" + the_sub_item).show();
        var the_url = '/manager/maint_check_sheet_task_logs/' + the_sub_item + '/show_erts'
        $.get(the_url);
      }
    }
    //########################################################################################################

    //########################################################################################################
    create_tasks_table(anTaskLogId) {
     //create_tasks_table() {
     var the_path = '/manager/maint_check_sheet_task_logs/get_task_erts/'

      var table_name = '#data-table-erts_' + anTaskLogId;
      var the_table = $(table_name).DataTable({
        ajax: {
          url: the_path,
          data: {id: anTaskLogId}
        },
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert_number',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          {
            data: {
              _: "opened_on_date.display",
              sort: "opened_on_date.timestamp"
            }
          },
          { data: 'assigned_to' },
          { data: 'status' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no tasks for this grouping."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        select: true
      });
    
      // $(table_name).on('shown.bs.tab', function (e) {
      //   $($.fn.dataTable.tables(true)).DataTable()
      //     .columns.adjust()
      //     .responsive.recalc();
      // });
    }
    //########################################################################################################

  };
  MaintCheckSheetLogs.initClass();
  return MaintCheckSheetLogs;
})();
    //window.common.debug( lnItemCount )
  //########################################################################################################

//########################################################################################################
window.maint_check_sheet_logs = new MaintCheckSheetLogs;