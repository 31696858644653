/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var Members = (function () {
  let app_types = undefined;
  Members = class Members {
    static initClass() {

      //########################################################################################################
      // Class variables
      app_types = [];
    }

    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.members')
    //########################################################################################################


    //########################################################################################################
    // /manager/members/index
    index() { }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/members/show
    show() {
      //window.common.debug( "members show" )
      $('#data-table-assigned_erts').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      $('#data-table-assigned_actions').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      $('#data-table-ert_type_defaults').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      return $('#data-table-ert_type_action_defaults').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/members/show
    phone_list() {
      //window.common.debug( "members phone_list" )
      return $('#data-table-phone_list').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/members/show
    mailing_list() {
      //window.common.debug( "members phone_list" )
      return $('#data-table-mailing_list').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    new_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>New Member</b> <br> The fields in red are required for a new member account. '
          },
          {
            element: '#ifire_account_div',
            intro: '<b>iFire Account?</b> <br> You must have an active iFire Account in order to log into iFire. ' +
              'This toggle tells iFire if the account is active or not.',
            position: 'top'
          },
          {
            element: '#active_member_div',
            intro: '<b>Active Member?</b> <br> You can create an iFire Account for someone that is not a member of your ' +
              'agency.  This toggle tells iFire if this account is for a member of your agency.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    default_access_help() {
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>Default iFire Access</b> <br> This page is used to set the default permissions for any new Active Member account. '
          },
          {
            intro: '<b>Default iFire Access</b> <br> These permissions will not be assigned to a new account where the' +
              'person is not and Active Member. '
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    ifire_application_access_help() {
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>Bulk iFire Access</b> <br> This page is used to grant or revoke iFire permissions for all user accounts. '
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/members/edit
    edit() {
      $('#member_is_active_member').on('change', window.members.change_member_is_active_member);
      window.members.change_member_is_active_member();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/members/login_status
    login_status() {
      //window.common.debug( "members phone_list" )
      return $('#data-table-login_list').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/members/access_levels
    access_levels() {
      // place listener on the account_approver
      //window.common.debug("Access Levels")
      $('#ifire_application').on('change', this.changed_app_types);
      $("#ifire_application").select2();
      $("#access_level").select2();
    }
    //########################################################################################################

    //########################################################################################################
    change_member_is_active_member() {
      window.common.debug($('#member_is_active_member').is(':checked'));

      if ($('#member_is_active_member').is(':checked')) {
        $("#paid_staff_div").show();
        $("#volunteer_staff_div").show();
      } else {
        $("#paid_staff_div").hide();
        $("#volunteer_staff_div").hide();
      }
    }
    //########################################################################################################


    //########################################################################################################
    set_app_types(anAppTypes) {
      return window.members.app_types = anAppTypes;
    }
    //window.common.debug(window.members.app_types[0])
    //########################################################################################################

    //########################################################################################################
    changed_app_types(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //window.common.debug( window.members.app_types[laClickedElem-1] )
      return window.members.set_access_levels(laClickedElem - 1);
    }
    //########################################################################################################

    //########################################################################################################
    set_access_levels(anApp) {

      // Display the App Permissions
      // Clear and rebuild the sub_type option list based on the selected Application
      // Of course we need to add a default.
      $("#access_level").empty();
      $('#access_level').append($('<option>', {
        value: 0,
        text: "Select Access Level"
      }));

      const the_app = window.members.app_types[anApp];

      return Array.from(the_app.app_permissions).map((element) =>
        $('#access_level').append($('<option>', {
          value: element.key,
          text: element.title
        })));
    }
  };
  Members.initClass();
  return Members;
})();
//########################################################################################################

//########################################################################################################
window.members = new Members;