/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
import { Calendar } from '@fullcalendar/core';
// import bootstrapPlugin from '@fullcalendar/bootstrap';
// import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
// import scrollGridPlugin from '@fullcalendar/scrollgrid';

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Dashboard {

  //########################################################################################################
  // always called
  init() { }
  //window.common.debug('Init called from window.dashboard')
  //########################################################################################################

  //########################################################################################################
  // /manager/dashboard/edit
  edit() { }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/dashboard/index
  index() {
    window.dashboard.index_setup_sign_up_sheets_table();
    window.dashboard.index_setup_calendar();
  }

  //########################################################################################################
  index_setup_sign_up_sheets_table() {
      var sign_up_table = $('#data-table-dash_sign_up').DataTable({
        ajax: '/manager/dashboard/get_sign_up_sheets',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'title' },
          {
            data: 'status',
            render: function (data, type) {
              var the_action = ""
              if (data.member_signed_up == "true") {
                the_action = '<FONT COLOR="green"><i class="icon-check"></i> Signed Up </FONT>'
              }
              else if (data.member_declined == "true") {
                the_action = '<FONT COLOR="red"><i class="icon-remove-sign"></i> Cannot Attend </FONT>'
              } else {
                the_action = '<FONT COLOR="red"><i class="icon-remove-sign"></i> Not Signed Up </FONT>'
              }
              return the_action;
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-calendar-alt fa-lg"></i> &nbsp; View Event</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming Signup Sheets."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        sign_up_table.destroy();
      });    
  }

  //########################################################################################################
  index_setup_calendar(){
    if($("#calendar").length) {
      var calendarEl = document.getElementById('calendar');

      var calendar = new Calendar(calendarEl, {
        contentHeight: "auto",
        expandRows: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'listDay,listWeek,listMonth'
        },
        initialView: 'listWeek',
        plugins: [listPlugin, interactionPlugin],
        views: {
          listDay: { buttonText: 'list day' },
          listWeek: { buttonText: 'list week' },
          listMonth: { buttonText: 'list month' }
        },

        editable: true,
        eventContent: function (arg) {
          if (arg.event.extendedProps.is_coverage_pending == true) {
            return arg.event.title + " - Coverage Pending";
          } else if (arg.event.extendedProps.user_scheduled == true) {
            return arg.event.title;
          } else {
            return arg.event.title + " - OPEN POSITIONS";
          }
        },
        events: '/manager/crew_schedules/load_users_events',
        eventClick: function (info) {
          //alert('Event: ' + info.event.title + ' | ID: ' + info.event.id);
          if (typeof info.event.id !== 'undefined' && info.event.extendedProps.is_event == true) {
            const url = "/manager/crew_schedules/" + info.event.id;
            return $.ajax({
              type: "GET",
              url,
              data: { id: info.event.id, origination: "dashboard" }
            });
          }
        },
        eventDidMount: function (info) {
          if (info.event.extendedProps.user_scheduled == false) {
            info.el.style.backgroundColor = '#ff3b3b';
            info.el.style.fontWeight = 'bold';
          } else {
            info.el.style.backgroundColor = '#90EE90';
            info.el.style.fontWeight = 'bold';
          }
          if (info.event.extendedProps.is_coverage_pending == true ||
            info.event.extendedProps.is_time_off_pending == true ||
            info.event.extendedProps.is_trade_pending == true) {
            info.el.style.backgroundColor = '#FFD700';
            info.el.style.fontWeight = 'bold';
          }
        }
      });

      calendar.render();
    }
  }
}
// window.common.debug( "Dashboard index" )
//########################################################################################################

//########################################################################################################
window.dashboard = new Dashboard;