/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import { Calendar } from '@fullcalendar/core';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var CrewSchedules = (function () {
  let tic_active_start = undefined;  // tic = the_index_calendar
  let tic_active_end = undefined;
  let tcc_active_start = undefined;  // tcc = the_crew_calendar
  let tcc_active_end = undefined;
  let tsc_active_start = undefined;  // tsc = the_shift_calendar
  let tsc_active_end = undefined;
  let saved_schedules = undefined;
  let row_count = undefined;
  let start_year = undefined;
  let start_month = undefined;
  let the_index_calendar = undefined;
  let the_crew_calendar = undefined;
  let the_shift_calendar = undefined;
  CrewSchedules = class CrewSchedules {
    static initClass() {
      //########################################################################################################
      // Class variables
      saved_schedules = [];
      row_count = 0;
      start_year = 0;
      start_month = 0;
    }

    //########################################################################################################
    // always called
    init() {
      return window.crew_schedules.row_count = 0;
    }
    //window.common.debug('Init called from window.crew_schedules')
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/index
    index() {
      window.crew_schedules.index_setup_all_coverage_table();
      window.crew_schedules.index_setup_my_coverage_table();
      window.crew_schedules.index_setup_my_trades_table();
      window.crew_schedules.index_setup_my_timeoff_table();
      window.crew_schedules.index_setup_shift_calendar();

      //window.common.debug("crew_schedules/index")
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#shift_calendar_div',
            intro: '<b>Shift Calendar (1) </b> <br> The Shift Calendar shows you all of the shifts for the week ' +
              'and highlights your shifts, shifts with pending changes, and shifts with open positions.',
            position: 'right'
          },
          {
            element: '#shift_calendar_div',
            intro: '<b>Shift Calendar (2) </b> <br> Click on the shift for more details on the shift.',
            position: 'right'
          },
          {
            element: '#admin_actions_div',
            intro: '<b>Items Requiring Attention </b> <br> Crew Scheduler Admins will see this box show up when ' +
              'there are pending Time Off Requests or Trades.',
            position: 'left'
          },
          {
            element: '#coverage_requests_div',
            intro: '<b>Coverage Requests</b> <br> All users will see this box when there are any unfilled ' +
              'Coverage Requests. ',
            position: 'left'
          },
          {
            element: '#my_coverage_requests_div',
            intro: '<b>My Coverage Requests</b> <br> This table contains your future Coverage Requests.',
            position: 'bottom'
          },
          {
            element: '#my_trades_div',
            intro: '<b>My Trades</b> <br> This table contains your future trade requests.',
            position: 'top'
          },
          {
            element: '#my_time_off_requests_div',
            intro: '<b>My Time Off Requests</b> <br> This table contains your future time off requests.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    index_overview() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Crew Scheduler Help</b> <br> The Crew Scheduler app is used to schedule one time or recurring shifts.'
          },
          {
            intro: '<b>Crew Scheduler Navigation </b> <br> The Crew Scheduler Navigation links will ' +
              'vary from member to member due to each member\'s account settings. ' +
              'Member accounts can even be set to not even show the Crew Scheduler.'
          },
          {
            intro: '<b>Crew Scheduler Dashboard</b> <br> Any member with any amount of Crew Scheduler ' +
              'access will see the Crew Scheduler Dashboard.  Click on the question mark for more info on the ' +
              ' Dashboard.'
          },
          {
            intro: '<b>Availability / Time Off</b> <br> This page is used to share your availability, ' +
              'request time off, and check the status of your time off requests.'
          },
          {
            intro: '<b>Crew Calendar</b> <br> This page provides you with a full calendar view of ' +
              'the schedule for each month.'
          },
          {
            intro: '<b>Recurring Schedules</b> <br> This is an admin page used to edit recurring schedules.'
          },
          {
            intro: '<b>Saved Crew Schedules</b> <br> This is an admin page used to edit saved ' +
              'non-recurring schedules.'
          },
          {
            intro: '<b>Shift Calendar</b> <br> This page provides you with a view of each days schedule ' +
              'including anyone that has stated their availability and all pending or approved time off requests.'
          },
          {
            intro: '<b>Trades</b> <br> This is an admin page used to manage pending trades.'
          },
          {
            intro: '<b>Settings</b> <br> This is an admin page used to manage Crew Scheduler settings.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    refetch_index_calendar() {
      if (window.crew_schedules.the_index_calendar !== undefined) {
        // alert("the_index_calendar update");
        window.crew_schedules.the_index_calendar.refetchEvents();
      } else {
        // alert("the_index_calendar is undefined");
      }
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_shift_calendar() {
      var calendarEl = document.getElementById('calendar');

      window.crew_schedules.the_index_calendar = new Calendar(calendarEl, {
        contentHeight: "auto",
        expandRows: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'listWeek,listMonth' // removed 'listDay'
        },
        initialView: 'listWeek',
        plugins: [listPlugin, interactionPlugin],
        views: {
          listDay: { buttonText: 'list day' },
          listWeek: { buttonText: 'list week' },
          listMonth: { buttonText: 'list month' }
        },

        editable: true,
        eventContent: function (arg) {
          var the_title = arg.event.title
          if (arg.event.extendedProps.is_coverage_pending == true) {
            the_title = the_title + " - Coverage Pending";
          }
          if (arg.event.extendedProps.is_time_off_pending == true &&
            arg.event.extendedProps.is_my_shift == false) {
            the_title = the_title + " - Time Off Pending";
          }
          if (arg.event.extendedProps.is_trade_pending == true) {
            the_title = the_title + " - Trade Pending";
          }
          if (arg.event.extendedProps.open_positions == true &&
            arg.event.extendedProps.is_my_shift == false) {
            the_title = the_title + " - OPEN POSITIONS";
          }
          return the_title
        },
        events: '/manager/crew_schedules/load_users_events',
        eventSourceSuccess: function (content, xhr) {
          const the_view = window.crew_schedules.the_index_calendar.view;
          // console.log(the_view);
          const start_date = new Date(the_view.activeStart)
          const end_date = new Date(the_view.activeEnd)
          console.log("the_index_calendar Start: " + start_date.toISOString());
          if ((window.crew_schedules.tic_active_start != start_date.valueOf()) || (window.crew_schedules.tic_active_end != end_date.valueOf())){
            window.crew_schedules.tic_active_start = start_date.valueOf();
            window.crew_schedules.tic_active_end = end_date.valueOf();
            const params = { start: start_date.toISOString(), end: end_date.toISOString() };
            $.post("/manager/crew_schedule_recurrings/create_schedules", params);
          }
          return content.eventArray;
        },
        eventClick: function (info) {
          //alert('Event: ' + info.event.title + ' | ID: ' + info.event.id);
          if (typeof info.event.id !== 'undefined' && info.event.extendedProps.is_event == true) {
            const url = "/manager/crew_schedules/" + info.event.id;
            return $.ajax({
              type: "GET",
              url,
              data: { id: info.event.id, origination: "crew_scheduler_dashboard" }
            });
          }
        },
        eventDidMount: function (info) {
          if (info.event.extendedProps.user_scheduled == false) {
            // Nobody is scheduled for this shift, so flag red
            var dotEl = info.el.getElementsByClassName('fc-list-event-dot')[0];
            if (info.event.extendedProps.open_positions == true) {
              dotEl.style.cssText = 'border: 5px solid red';
              info.el.style.color = 'red';
            } else {
              dotEl.style.cssText = 'border: 5px solid black';
              info.el.style.color = 'black';
            }
            info.el.style.fontWeight = 'bold';
          } else {
            // The current user is signed up for this shift, so flag as green
            var dotEl = info.el.getElementsByClassName('fc-list-event-dot')[0];
            dotEl.style.cssText = 'border: 5px solid green';
            info.el.style.fontWeight = 'bold';
            info.el.style.color = 'green';
          }

          if (info.event.extendedProps.is_coverage_pending == true ||
            info.event.extendedProps.is_time_off_pending == true ||
            info.event.extendedProps.is_trade_pending == true) {
            // This shift has a pending change, so flag as orange
            var dotEl = info.el.getElementsByClassName('fc-list-event-dot')[0];
            dotEl.style.cssText = 'border: 5px solid #EC8E00';
            info.el.style.fontWeight = 'bold';
            info.el.style.color = '#EC8E00';
          }
        }
      });

      window.crew_schedules.the_index_calendar.render();
      
      $(window).resize(function (e) { //set window resize listener
        window.crew_schedules.update_index_calendar($(window).width()); //or you can use $(document).width()
      });
      window.crew_schedules.update_index_calendar($(window).width());
    }
    //########################################################################################################

    //########################################################################################################
    update_index_calendar(screenWidth) {
      var header = {};
      if (screenWidth < 700) {
        header = {
          left: 'prev,next',
          center: 'title',
          right: ''
        };
      } else {
        header = {
          left: 'prev,next today',
          center: 'title',
          right: 'listWeek,listMonth' // removed 'listDay'
        };
      }
      window.crew_schedules.the_index_calendar.setOption('headerToolbar', header);
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_all_coverage_table() {
      var coverage_requests_table = $('#data-table-all_coverage_requests').DataTable({
        ajax: '/manager/crew_schedule_trades/get_all_coverage_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: {
              _: "shift_date.display",
              sort: "shift_date.timestamp"
            }
          },
          { data: 'assignment_name' },
          { data: 'member' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_crew_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_trades/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="fas fa-user-plus fa-lg"></i> &nbsp; Manage Coverage</a >';
              }
              else if (data.is_crew_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_trades/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="fas fa-user-plus fa-lg"></i> &nbsp; Offer Coverage</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming Coverage Requests."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        coverage_requests_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_my_coverage_table() {
      var coverage_requests_table = $('#data-table-coverage_requests').DataTable({
        ajax: '/manager/crew_schedule_trades/get_my_coverage_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: {
              _: "shift_date.display",
              sort: "shift_date.timestamp"
            }
          },
          { data: 'assignment_name' },
          { data: 'status' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_trades/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming Coverage Requests."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        coverage_requests_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_my_trades_table() {
      var trades_table = $('#data-table-trades').DataTable({
        ajax: '/manager/crew_schedule_trades/get_my_trade_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: {
              _: "shift_date.display",
              sort: "shift_date.timestamp"
            }
          },
          { data: 'assignment_name' },
          { data: 'status' },
          { data: 'trade_from' },
          { data: 'trade_to' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_trades/' + data.the_trade_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming Trades."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        trades_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_my_timeoff_table() {
      var time_off_table = $('#data-table-time_off').DataTable({
        ajax: '/manager/crew_schedule_time_off_requests/get_my_time_off_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'request_title' },
          { data: 'hours_paid' },
          {
            data: {
              _: "time_off_date.display",
              sort: "time_off_date.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_time_off_requests/' + data.the_time_off_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming time off."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        time_off_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/new
    new() { }
    //window.common.debug( "crew_schedules/new" )
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/new
    edit() {
      //window.common.debug( "crew_schedules/edit" )
      $('#crew_schedule_crew_schedule_template_id').on('change', this.changed_schedule);

      // Single Instance tables
      $('#data-table-crew_schedule_assignments').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      $('#data-table-pending_trades').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      $('#data-table-time_off').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      $('#data-table-availability').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });

      // Recurring tables
      $('#data-table-crew_schedule_occurrences').dataTable({
        "aaSorting": [[1, "asc"]],
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      return $('#data-table-crew_schedule_exceptions').dataTable({
        "aaSorting": [[1, "asc"]],
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/recurring_schedules
    recurring_schedules() {
      return $('#data-table-crew_schedules').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    recurring_schedules_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Recurring Schedules </b> <br> This page provides a list view of all of the recurring ' +
              'schedules.  This is the only place to find recurring schedules that are not published.'
          },
          {
            element: '#data-table-crew_schedules',
            intro: '<b>Recurring Schedule </b> <br> Here are your Recurring Schedules.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/show
    show() {
      alert("Show Called");
    }
    //########################################################################################################

    //########################################################################################################
    show_help() {
      // window.common.debug('called index_help');
      var intro = introJs("#the-modal-form");
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Crew Schedule Details </b> <br> The top of the modal window shows the Crew Schedule details.'
          },
          {
            intro: '<b>Crew Schedule Assignments </b> <br> The table shows the assignments for the shift.',
            position: 'top'
          },
          {
            intro: '<b>Crew Schedule Actions (1/5) </b> <br> The actions shown will be determined by the access level ' +
              'granted to your account.',
            position: 'top'
          },
          {
            intro: '<b>Crew Schedule Actions (2/5) </b> <br> Users with Basic Crew Scheduler access will only ' +
              'be able to see the shifts and will have no actions.',
            position: 'top'
          },
          {
            intro: '<b>Crew Schedule Actions (3/5) </b> <br> Users with Standard User Crew Scheduler access will ' +
              'be permitted to request Coverage and Trades.',
            position: 'top'
          },
          {
            intro: '<b>Crew Schedule Actions (4/5) </b> <br> Users with Admin Crew Scheduler access will ' +
              'be permitted to request Coverage, Trades, and make shift assignments.',
            position: 'top'
          },
          {
            intro: '<b>Crew Schedule Actions (5/5) </b> <br> Users with Admin Crew Scheduler access will also ' +
              'be permitted to edit and delete the shift.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    force_start(aaYear, aaMonth) {
      //window.common.debug( "force_start" )
      window.crew_schedules.start_year = aaYear;
      return window.crew_schedules.start_month = aaMonth;
    }
    //########################################################################################################

    //########################################################################################################
    repeating_event(event) {
      if ($("#member_is_active_member").is(":checked")) {
        //window.common.debug('CHECKED')
        $("#start_timepicker").hide();
        $("#end_timepicker").hide();
        return $("#crew_schedule_allDay").hide();
      } else {
        //window.common.debug('unchecked')
        $("#start_timepicker").show();
        $("#end_timepicker").show();
        return $("#crew_schedule_allDay").show();
      }
    }
    //########################################################################################################

    //########################################################################################################
    add_schedules(aaSchedules) {
      return window.crew_schedules.saved_schedules = aaSchedules;
    }
    //########################################################################################################

    //########################################################################################################
    changed_schedule(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //window.common.debug( window.pos.vendors[laClickedElem-1] )

      if (laClickedElem > 0) {
        const lrSchedule = window.crew_schedules.saved_schedules[laClickedElem - 1];

        const url = "/manager/crew_schedule_positions/get_positions/" + lrSchedule._id;
        $.get(url);

        $('#crew_schedule_shift_name').val(lrSchedule.shift_name);
        $('#crew_schedule_location').val(lrSchedule.location);

        $('#start_timepicker').timepicker('setTime', new Date(lrSchedule.date_start));
        $('#end_timepicker').timepicker('setTime', new Date(lrSchedule.date_end));

        

        if (lrSchedule.allDay) {
          $('#crew_schedule_allDay').prop("checked", true);

          $("#start_time_div").hide();
          return $("#end_time_div").hide();
        } else {
          $('#crew_schedule_allDay').prop("checked", false);
          $("#start_time_div").show();
          return $("#end_time_div").show();
        }
      } else {
        return $('#crew_schedule_location').val("");
      }
    }
    //########################################################################################################

    //########################################################################################################
    refetch_crew_calendar() {
      if (window.crew_schedules.the_crew_calendar !== undefined) {
        // alert("the_crew_calendar update");
        window.crew_schedules.the_crew_calendar.refetchEvents();
      } else {
        // alert("the_crew_calendar is undefined");
      }
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/crew_schedules/crew_calendar
    crew_calendar() {
      var calendarEl = document.getElementById('calendar');

      window.crew_schedules.the_crew_calendar = new Calendar(calendarEl, {
        // initialView: 'dayGridMonth',
        initialView: 'dayGridWeek',
        buttonText: {
          day: 'Day',
          month: 'Month',
          today: 'Today',
          week: 'Week'
        },
        contentHeight: "auto",
        expandRows: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        plugins: [dayGridPlugin, interactionPlugin],
        editable: true,
        eventContent: function (arg, createElement) {
          let the_event = document.createElement('div');
          var the_html = "";

          the_html = "<div class='rounded-top' style='background-color: black; color: white; font-weight: bold;'>&nbsp;" + arg.event.title +
            " - " + arg.event.extendedProps.location + "</div>";

          if (arg.event.extendedProps.event_assignments.length > 0) {
            the_html += "<div style='background-color: #FFE4C4;'>";
            the_html += "<table>";

            arg.event.extendedProps.event_assignments.forEach(function (item, index) {
              if (item.assignment_open == true) {
                the_html += "<tr style='background-color: red; color: white;'>";
              } else if (item.members_assignment == true) {
                the_html += "<tr style='background-color: green; color: white;'>";
              } else {
                the_html += "<tr style='color: black; '>";
              }
              the_html += "<td style='border: 0px; overflow: hidden;'>";
              the_html += "&nbsp;&nbsp;" + item.name;
              the_html += "</td>";
              the_html += "<td style='border: 0px; text-align:right'>";
              if (arg.event.allDay == true) {
                the_html += "&nbsp;&nbsp; All Day &nbsp;";
              } else {
                the_html += "&nbsp;&nbsp;" + moment(arg.event.extendedProps.shift_start).format('HH:mm') + '-' + moment(arg.event.extendedProps.shift_end).format('HH:mm') + "&nbsp;";
              }
              the_html += "</td>";
              the_html += "</tr>";
            });

            the_html += "</table>";
            the_html += "</div>";
          }

          the_event.innerHTML = the_html;

          let arrayOfDomNodes = [the_event];
          return { domNodes: arrayOfDomNodes };
        },
        //events: '/manager/crew_schedules/load_events',
        events: '/manager/crew_schedules/faster_get_load_data',
        // eventSources: [
        //   {
        //     url: '/manager/crew_schedules/load_events',
        //     method: 'GET'
        //   },
        //   {
        //     url: '/manager/crew_schedules/create_schedules',
        //     method: 'POST'
        //   }
        // ],
        eventSourceSuccess: function (content, xhr) {
          const the_view = window.crew_schedules.the_crew_calendar.view;
          // console.log(the_view);
          const start_date = new Date(the_view.activeStart)
          const end_date = new Date(the_view.activeEnd)
          console.log("the_crew_calendar Start: " + start_date.toISOString());
          if ((window.crew_schedules.tcc_active_start != start_date.valueOf()) || (window.crew_schedules.tcc_active_end != end_date.valueOf())){
            window.crew_schedules.tcc_active_start = start_date.valueOf();
            window.crew_schedules.tcc_active_end = end_date.valueOf();
            const params = { start: start_date.toISOString(), end: end_date.toISOString() };
            $.post("/manager/crew_schedule_recurrings/create_schedules", params);
          }
          return content.eventArray;
        },
        eventClick: function (info) {
          //alert('Event: ' + info.event.title + ' | ID: ' + info.event.id);
          if (typeof info.event.id !== 'undefined') {
            const url = "/manager/crew_schedules/" + info.event.id;
            return $.ajax({
              type: "GET",
              url,
              data: { id: info.event.id, origination: "crew_calendar" }
            });
          }
        },
        select: function (info) {
          //window.common.debug('New Event ' + start + " | " + end)
          const params = { start_date: info.start, end_date: info.start };
          return $.get("/manager/crew_schedules/create_schedule", params);
        },
        selectable: true
      });

      window.crew_schedules.the_crew_calendar.render();
      // window.common.debug("crew_schedules/crew_calendar")
    }
    //########################################################################################################

    //########################################################################################################
    crew_calendar_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#calendar',
            intro: '<b>Crew Calendar </b> <br> This page provides a calendar view of the shifts.  Your shifts ' +
              '(green) and open shifts (red) are highlighted.',
            position: 'top'
           },
          {
            element: '#calendar',
            intro: '<b>Crew Calendar </b> <br> Click on the shift for more details on the shift and to gain ' +
              'access to features to request Coverage or a Trade.  Admins will also have the capability to ' +
              'edit the shift.',
            position: 'top'
          },
          {
            element: '#calendar',
            intro: '<b>Crew Calendar </b> <br> Click on the blank space for a date to create a new shift. ' +
              'Note that only Admins can add new shifts.',
            position: 'top'
           }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    reports() {

    }
    //########################################################################################################

    //########################################################################################################
    refetch_shift_calendar() {
      if (window.crew_schedules.the_shift_calendar !== undefined) {
        // alert("the_shift_calendar update");
        window.crew_schedules.the_shift_calendar.refetchResources();
        window.crew_schedules.the_shift_calendar.refetchEvents();
      } else {
        // alert("the_shift_calendar is undefined");
      }
    }
    //########################################################################################################

    //########################################################################################################
    shift_calendar() {
      var calendarEl = document.getElementById('calendar');

      window.crew_schedules.the_shift_calendar = new Calendar(calendarEl, {
        eventChange: function (changeInfo) {
          return $.ajax({
            method: "POST",
            url: '/manager/crew_schedule_assignments/shift_calendar_update/',
            data: {
              assignment_id: changeInfo.event.id,
              start: changeInfo.event.start,
              end: changeInfo.event.end
            }
          });
        },
        eventClick: function (info) {
          if (info.event.extendedProps.crew_schedule_id != null) {
            const url = "/manager/crew_schedules/" + info.event.extendedProps.crew_schedule_id;
            return $.ajax({
              type: "GET",
              url,
              data: {
                id: info.event.extendedProps.crew_schedule_id,
                origination: "shift_calendar"
              }
            });
          } else if (info.event.extendedProps.crew_schedule_avail_id != null) {
            const url = "/manager/crew_schedule_availability_entries/" + info.event.extendedProps.crew_schedule_avail_id + "/edit"
            return $.ajax({
              type: "GET",
              url
            });
          } else if (info.event.extendedProps.crew_schedule_time_off_id != null) {
            const url = "/manager/crew_schedule_time_off_requests/" + info.event.extendedProps.crew_schedule_time_off_id + "/edit"
            return $.ajax({
              type: "GET",
              url
            });
          }
        },
        events: '/manager/crew_schedules/load_shifts',
        eventSourceSuccess: function (content, xhr) {
          const the_view = window.crew_schedules.the_shift_calendar.view;
          // console.log(the_view);
          const start_date = new Date(the_view.activeStart)
          const end_date = new Date(the_view.activeEnd)
          console.log("the_shift_calendar Start: " + start_date.toISOString());
          if ((window.crew_schedules.tsc_active_start != start_date.valueOf()) || (window.crew_schedules.tsc_active_end != end_date.valueOf())){
            window.crew_schedules.tsc_active_start = start_date.valueOf();
            window.crew_schedules.tsc_active_end = end_date.valueOf();
            const params = { start: start_date.toISOString(), end: end_date.toISOString() };
            $.post("/manager/crew_schedule_recurrings/create_schedules", params);
            // return content.eventArray;
          }
          // return content.eventArray;
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
        },
        height: '1000px',
        initialView: 'resourceTimelineDay',
        nowIndicator: true,
        plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin, bootstrapPlugin, scrollGridPlugin],
        refetchResourcesOnNavigate: true,
        resourceAreaHeaderContent: 'Staff',
        resourceGroupField: 'groupId',
        resourceGroupLabelClassNames: 'fc-resource-group-bold',
        resourceOrder: 'title',
        resources: function (fetchInfo, successCallback, failureCallback) {
          //alert(fetchInfo.start);
          const start_date = new Date(fetchInfo.start)
          const end_date = new Date(fetchInfo.end)
          // return $.ajax({
          //   type: "GET",
          //   url: '/manager/crew_schedules/load_resources',
          //   data: {
          //     start: fetchInfo.start,
          //     end: fetchInfo.end
          //   }
          // });
          return $.ajax({
            type: "GET",
            url: '/manager/crew_schedules/load_resources',
            data: {
              start: start_date.toISOString(),
              end: end_date.toISOString()
            }
          });
        },
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        stickyHeaderDates: true,
        themeSystem: 'bootstrap'
      });

      window.crew_schedules.the_shift_calendar.render();
      // window.common.debug("crew_schedules/shift_calendar")
    }
    //########################################################################################################

    //########################################################################################################
    shift_calendar_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#calendar',
            intro: '<b>Shift Calendar </b> <br> This page provides a list view of the shifts for the selected ' +
              'day.  Your shifts (green) and open shifts (red) are highlighted.  This page will also show a ' +
              'summary of the members who are available and those who have requested time off.'
          },
          {
            element: '#calendar',
            intro: '<b>Shift Calendar </b> <br> Click on the shift bars for more details on the shift and to gain ' +
              'access to features to request Coverage or a Trade.  Admins will also have the capability to ' +
              'edit the shift.'
          },
          {
            element: '#calendar',
            intro: '<b>Shift Calendar </b> <br> Click on the Availability Entry or Time Off ' +
              ' bars for more details.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    setFilledBy(the_assignment_id) {
      //window.common.debug( "setFilledBy" )
      const the_element = "#position_" + the_assignment_id;
      const the_member = $(the_element).val();
      //window.common.debug( "Selected Member |" + the_member + "|   Assignment |" + the_assignment_id + "|" )

      $.post("/manager/crew_schedule_assignments/set_assignment",
        {
          assignment_id: the_assignment_id,
          member_id: the_member
        });
 
      return $(the_element).prop('selectedIndex', 0);
    }
  };
  CrewSchedules.initClass();
  return CrewSchedules;
})();
//########################################################################################################

//########################################################################################################
window.crew_schedules = new CrewSchedules;