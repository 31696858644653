import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtTypeDefaults {

    //########################################################################################################
    // always called
    init() {
        //window.common.debug('Init called from window.ert_category_items');
    }
    //########################################################################################################

    //########################################################################################################
    index() {
        var ert_type_defaults_table = $('#data-table-ert_type_defaults').DataTable({
            fixedHeader: true,
            paging: false,
            responsive: true,
            select: true
        });

        document.addEventListener('turbolinks:load', function () {
            ert_type_defaults_table.destroy();
        });
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
        // window.common.debug('called index_help');
        var intro = introJs();
        intro.setOptions({
            showStepNumbers: false,
            steps: [
                {
                    intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
                },
                {
                    element: '#facility_header',
                    intro: '<b>Facility</b> <br> The Facility name. ',
                    position: 'bottom'
                },
                {
                    element: '#default_user_header',
                    intro: '<b>Default Assigned User</b> <br> The Selected Member will automatically be assigned new ' +
                        'ERTs for this ERT Type and for this Facility.  The author of the ERT will be assigned the new ' +
                        'ERT if a default is not selected.',
                    position: 'bottom'
                },
                {
                    element: '#regular_email_header',
                    intro: '<b>Regular Email</b> <br> Only one email address may be entered here.  This is intended to be ' +
                        'an email distribution list.  This email address will be emailed when a new ERT is opened, updated, or closed and the ' +
                        'out of service status is set to no.',
                    position: 'bottom'
                },
                {
                    element: '#oos_email_header',
                    intro: '<b>OOS Email</b> <br> Only one email address may be entered here.  This is intended to be ' +
                        'an email distribution list.  This email address will be emailed when a new ERT is opened, updated, or closed and the ' +
                        'out of service status is set to yes.',
                    position: 'bottom'
                }
            ]
        });

        intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    new() {

    }
    //########################################################################################################

    //########################################################################################################
    edit() {

    }
    //########################################################################################################

}

//########################################################################################################
window.ert_type_defaults = new ErtTypeDefaults;