/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var MemberRoles = (function() {
  let categories = undefined;
  let roles = undefined;
  MemberRoles = class MemberRoles {
    static initClass() {
  
      //########################################################################################################
      // Class variables
      categories = [];
      roles = [];
    }

    //########################################################################################################
    // always called
    init() {}
      //window.common.debug('Init called from window.MemberRoles')
    //########################################################################################################

    //########################################################################################################
    set_member_roles_data(anCategories, anRoles) {
      //window.common.debug( anCategories )
      //window.common.debug( anRoles )
      window.member_roles.categories = anCategories;
      return window.member_roles.roles = anRoles;
    }
  };
  MemberRoles.initClass();
  return MemberRoles;
})();
  //########################################################################################################

//########################################################################################################
window.member_roles = new MemberRoles;