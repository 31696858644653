/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class UsefulLinks {


    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.useful_links')
    //########################################################################################################

    //########################################################################################################
    // /manager/useful_links/index
    index() {
        $('#data-table-useful_links').DataTable({
            ajax: {
                url: '/manager/useful_links/get_useful_links'
            },
            columns: [
                { data: 'description', },
                { data: 'url', },
                {
                    data: 'actions',
                    render: function (data, type) {
                        var the_action = '<table> <tr style="background: transparent;">';
                        the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                        the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/useful_links/' + data.useful_link_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="fas fa-cog"></i> &nbsp; Edit</a >';
                        the_action += '</td>';
                        the_action += '</tr></table>';

                        return the_action;
                    }
                }
            ],
            fixedHeader: true,
            paging: false,
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                                '</div>' +
                                '<div class="row">' +
                                '<span>' + col.data + '</span> ' +
                                '</div> <br>' :
                                '';
                        }).join('');

                        return data ?
                            data :
                            false;
                    }
                }
            },
            searchPane: false,
            select: true
        });
    }

    //########################################################################################################
    index_help() {
        // window.common.debug('called index_help');
        var intro = introJs();
        intro.setOptions({
            showStepNumbers: false,
            steps: [
                {
                    intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
                },
                {
                    element: '#new_action',
                    intro: '<b>Add a New Vehicle</b> <br> Click here to add a new Vehicle. ' +
                        'Note that useful_links cannot be deleted, but they can be disabled. ',
                    position: 'bottom'
                },
                {
                    element: '#data-table-useful_links',
                    intro: '<b>useful_links</b> <br> Here is a listing of your useful_links. ',
                    position: 'bottom'
                },
                {
                    intro: '<b>useful_links</b> <br> ' +
                        'useful_links can be renamed or enabled/discabled at any time.'
                }
            ]
        });

        intro.start();
    }
    //########################################################################################################

}
//########################################################################################################

//########################################################################################################
window.useful_links = new UsefulLinks;