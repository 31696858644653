/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var InventoryItems = (function() {
  let ert_types = undefined;
  let ert_subtypes = undefined;
  let ert_categories = undefined;
  let theInventoryItem = undefined;
  InventoryItems = class InventoryItems {
    static initClass() {
  
    //########################################################################################################
    // Class variables
      ert_types = [];
      ert_subtypes = [];
      ert_categories = [];
      theInventoryItem = null;
    }

    //########################################################################################################
    // always called
    init() {
      return window.common.debug('Init called from window.inventory_items');
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {}
    //
    //########################################################################################################


    //########################################################################################################
    // /manager/erts/new
    new() {
      //window.common.debug( "inventory_items new begin" )

      $('#inventory_item_ert_type_id').on('change', this.changed_ert_type);

      if (window.inventory_items.theInventoryItem.ert_type_id != null) {
        //window.common.debug( "Update ERT Settings, ERT Type selected" )
        const laClickedElem = $('#ert_ert_type_id').prop("selectedIndex");
        //window.common.debug( laClickedElem )
        //window.common.debug( window.erts.ert_types[laClickedElem-1] )
        window.inventory_items.set_form( laClickedElem-1 );
        $('#ert_ert_subtype_id').val( window.erts.theErt.ert_subtype_id );
        return $('#ert_ert_category_id').val( window.erts.theErt.ert_category_id );
      } else {
        //window.common.debug( "No need to update ERT Settings, ERT Type not selected" )
        $('#ert_sub_types_header').hide();
        return $('#ert_categories_header').hide();
      }
    }

    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    edit() {
      return $('#inventory_item_ert_type_id').on('change', this.changed_ert_type);
    }
    //
    //########################################################################################################

    //########################################################################################################
    set_inventory_item_data(anItem) {
      //window.common.debug( anErt )
      return window.inventory_items.theInventoryItem = anItem;
    }
    //########################################################################################################

    //########################################################################################################
    add_ert_types(anErtTypes) {
      return window.inventory_items.ert_types = anErtTypes;
    }
      //window.common.debug(window.erts.ert_types[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_subtypes(anErtSubtypes) {
      return window.inventory_items.ert_subtypes = anErtSubtypes;
    }
      //window.common.debug(window.erts.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_categories(anErtCategories) {
      return window.inventory_items.ert_categories = anErtCategories;
    }
      //window.common.debug(window.erts.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    changed_ert_type(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //    window.common.debug( window.inventory_items.ert_types[laClickedElem-1] )
      return window.inventory_items.set_form( laClickedElem-1 );
    }
    //########################################################################################################

    //########################################################################################################
    set_form( anErtType ) {
      const lrErtType = window.inventory_items.ert_types[ anErtType ];

      if ((anErtType != null) && (anErtType >= 0)) {
        // Clear and rebuild the sub_type option list based on the selected ERT type
        // Of course we need to add a default ERT Subtype too.
        $("#inventory_item_ert_subtype_id").empty();
        $('#inventory_item_ert_subtype_id').append($('<option>', {
          value: 0,
          text : "Select an ERT Subtype"
        }));

        // Clear and rebuild the category option list based on the selected ERT type
        // Of course we need to add a default ERT Subtype too.
        $("#inventory_item_ert_category_id").empty();
        $('#inventory_item_ert_category_id').append($('<option>', {
          value: 0,
          text : "Select an ERT Category"
        }));

        // Display the ERT Subtypes if the ERT type uses them
        if (lrErtType.has_subtypes) {
          $('#ert_sub_types_header').show();
          $('#ert_categories_header').hide();

          return (() => {
            const result = [];
            for (let element of Array.from(window.inventory_items.ert_subtypes)) {
            // Only add the ERT subtype if it belongs to the selected ERT type
              if (lrErtType._id === element.ert_type_id) {
                result.push($('#inventory_item_ert_subtype_id').append($('<option>', {
                  value: element._id,
                  text : element.name
                })));
              } else {
                result.push(undefined);
              }
            }
            return result;
          })();
        } else if (lrErtType.has_categories) {
          $('#ert_sub_types_header').hide();
          $('#ert_categories_header').show();

          return (() => {
            const result1 = [];
            for (let element of Array.from(window.inventory_items.ert_categories)) {
            // Only add the ERT subtype if it belongs to the selected ERT type
              if (lrErtType._id === element.ert_type_id) {
                result1.push($('#inventory_item_ert_category_id').append($('<option>', {
                  value: element._id,
                  text : element.name
                })));
              } else {
                result1.push(undefined);
              }
            }
            return result1;
          })();
        } else {
          $('#ert_sub_types_header').hide();
          return $('#ert_categories_header').hide();
        }
      }
    }
  };
  InventoryItems.initClass();
  return InventoryItems;
})();

  //
  //########################################################################################################

//########################################################################################################
window.inventory_items = new InventoryItems;