/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var ImsSearch = (function() {
  let ert_types = undefined;
  let ert_subtypes = undefined;
  let allInventoryItems = undefined;
  let allVehicles = undefined;
  let allCompartments = undefined;
  ImsSearch = class ImsSearch {
    static initClass() {
  
    //########################################################################################################
    // Class variables
      ert_types = [];
      ert_subtypes = [];
      allInventoryItems = null;
      allVehicles = null;
      allCompartments = null;
    }

    //########################################################################################################
    // always called
    init() {}
      //window.common.debug('Init called from window.ims_search')
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {}
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() {
      //window.common.debug( "inventory_item_instances new begin" )

      $('#inventory_item_instance_inventory_item_id').on('change', this.changed_item);
      $('#location_assignment_location_id').on('change', this.changed_location);
      $('#member_assignment_member_id').on('change', this.changed_member);
      $('#vehicle_assignment_vehicle_id').on('change', this.changed_vehicle);

      if (window.inventory_item_instances.theInventoryItem.inventory_item_id != null) {
        //window.common.debug( "Update Item Details, Item selected" )
        let laClickedElem;
        return laClickedElem = $('#inventory_item_instance_inventory_item_id').prop("selectedIndex");
        //window.common.debug( laClickedElem )
        //window.common.debug( window.erts.ert_types[laClickedElem-1] )
      } else {
        //window.common.debug( "No need to update Item Details, Item not selected" )
        $('#serial_div').hide();
        return $('#quantity_div').hide();
      }
    }

      //window.common.debug( "inventory item instance new end" )
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    show() {}
    //
    //########################################################################################################

    //########################################################################################################
    set_all_inventory_items(anItems) {
      //window.common.debug( anErt )
      return window.inventory_item_instances.allInventoryItems = anItems;
    }
    //########################################################################################################

    //########################################################################################################
    set_vehicles(anItems) {
      //window.common.debug( anErt )
      return window.inventory_item_instances.allVehicles = anItems;
    }
    //########################################################################################################

    //########################################################################################################
    set_compartments(anItems) {
      //window.common.debug( anErt )
      return window.inventory_item_instances.allCompartments = anItems;
    }
    //########################################################################################################

    //########################################################################################################
    changed_location(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");

      $('#member_assignment_member_id').val( '' );
      $('#vehicle_assignment_vehicle_id').val( '' );
      $("#compartment_assignment_compartment_id").empty();
      return $('#compartment_assignment_compartment_id').append($('<option>', {
      value: 0,
      text : "Select a Compartment"
      }));
    }
    //########################################################################################################

    //########################################################################################################
    changed_member(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");

      $('#location_assignment_location_id').val( '' );
      $('#vehicle_assignment_vehicle_id').val( '' );
      $("#compartment_assignment_compartment_id").empty();
      return $('#compartment_assignment_compartment_id').append($('<option>', {
      value: 0,
      text : "Select a Compartment"
      }));
    }
    //########################################################################################################

    //########################################################################################################
    changed_vehicle(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");

      $('#location_assignment_location_id').val( '' );
      $('#member_assignment_member_id').val( '' );

      if (laClickedElem === 0) {
        $("#compartment_assignment_compartment_id").empty();
        return $('#compartment_assignment_compartment_id').append($('<option>', {
        value: 0,
        text : "Select a Compartment"
        }));
      } else {
        return window.inventory_item_instances.update_compartments( laClickedElem-1 );
      }
    }
    //########################################################################################################

    //########################################################################################################
    changed_item(event) {
      let laClickedElem;
      return laClickedElem = $(event.target).prop("selectedIndex");
    }
      //    window.common.debug( laClickedElem )
      //    window.common.debug( window.inventory_item_instances.ert_types[laClickedElem-1] )
    //########################################################################################################

    //########################################################################################################
    update_compartments( anItemId ) {
      let element;
      const lrVehicle = window.inventory_item_instances.allVehicles[ anItemId ];
      let laCompartments = [];

      $("#compartment_assignment_compartment_id").empty();
      $('#compartment_assignment_compartment_id').append($('<option>', {
      value: 0,
      text : "Select a Compartment"
      }));

      //    lrVehicle.vehicle_compartments.sort function(a, b) ->
      //      var nameA = a.name.toUpperCase() # ignore upper and lowercase
      //      var nameB = b.name.toUpperCase() # ignore upper and lowercase
      //      if (nameA < nameB)
      //        return -1
      //      if (nameA > nameB)
      //        return 1
      //
      //      # names must be equal
      //      return 0

      for (element of Array.from(window.inventory_item_instances.allCompartments)) {
        // Only add the ERT subtype if it belongs to the selected ERT type
        if (lrVehicle._id === element.vehicle_id) {
          laCompartments.push(element);
        }
      }

      if (typeof laCompartments !== "undefined") {
        laCompartments = window.inventory_item_instances.sort_objects(laCompartments, "name");

        return (() => {
          const result = [];
          for (element of Array.from(laCompartments)) {
            result.push($('#compartment_assignment_compartment_id').append($('<option>', {
            value: element._id,
            text : element.name
            })));
          }
          return result;
        })();
      }
    }
    //########################################################################################################

    //########################################################################################################
    sort_objects(array, key) {
      return array.sort(function(a,b) {
        if (a[key] < b[key]) {
          return -1;
        } else if (a[key] > b[key]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  };
  ImsSearch.initClass();
  return ImsSearch;
})();
//########################################################################################################

//########################################################################################################
window.ims_search = new ImsSearch;