/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtSetUpWizard {
    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.ert_search')
    //########################################################################################################

    //########################################################################################################
    // /manager/ert_search/edit
    edit() { }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/ert_search/index
    index() { }
    //########################################################################################################

    //########################################################################################################
    prerequisite_main_help() {
        // window.common.debug('called index_help');
        var intro = introJs();
        intro.setOptions({
            showStepNumbers: false,
            steps: [
                {
                    intro: "<b>ERT Setup Wizard Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
                },
                {
                    intro: '<b>ERT Setup Wizard Help</b> <br> The ERT System requires you to have setup your agencies Facilities ' +
                        'and Vehicles.  You will need to have met the prerequisites in order to continue to setup ERTs.'
                }
            ]
        });

        intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/ert_search/update
    update() {
        $('#data-table-erts').DataTable({
            ajax: {
                type: "GET",
                url: "/manager/ert_search/get_ert_data",
                data: function (d) {
                    if ($('#phase_id').length) {
                        d.phase_id = $('#phase_id').val();
                        d.ert_type_id = $('#ert_type_id').val();
                        d.facility_id = $('#facility_id').val();
                        d.vehicle_id = $('#vehicle_id').val();
                        d.assigned_to_id = $('#assigned_to_id').val();
                        d.originator_id = $('#originator_id').val();
                        d.opened_on_symbol = $("#opened_on_symbol").val();
                        d.opened_on = $("#opened_on").prop("value");
                        d.key_word = $("#key_word").prop("value");
                    } else {
                        d.phase_id = "";
                        d.ert_type_id = "";
                        d.facility_id = "";
                        d.vehicle_id = "";
                        d.assigned_to_id = "";
                        d.originator_id = "";
                        d.opened_on_symbol = "";
                        d.opened_on = "";
                        d.key_word = "";
                    }
                }
            },
            deferRender: true,
            dom: "<'row'<'col-sm-12 pb-3'B>>" +
                "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                'excelHtml5',
                'csvHtml5',
                'pdfHtml5',
                'print'
            ],
            columns: [
                {
                    data: 'placeholder',
                    render: function (data, type) {
                        return '';
                    }
                },
                {
                    data: 'ert',
                    render: function (data, type) {
                        return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
                    }
                },
                {
                    data: 'title',
                    defaultContent: ""
                },
                { data: 'type' },
                { data: 'subtype' },
                { data: 'vehicle' },
                {
                    data: {
                        _: "opened_on.display",
                        sort: "opened_on.timestamp"
                    }
                },
                { data: 'opened_by' },
                { data: 'status' },
                { data: 'assigned_to' },
                {
                    data: 'comment',
                    render: function (data, type) {
                        var the_comment = "No Comments"
                        if (!!data.member) {
                            the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
                        }
                        return the_comment;
                    }
                }
            ],
            fixedHeader: true,
            language: {
                emptyTable: "There are no ERTs that meet your search criteria."
            },
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                                '</div>' +
                                '<div class="row">' +
                                '<span>' + col.data + '</span> ' +
                                '</div> <br>' :
                                '';
                        }).join('');

                        return data ?
                            data :
                            false;
                    }
                }
            },
            stateSave: true,
            select: true
        });
    }
}
//########################################################################################################

//########################################################################################################
window.ert_set_up_wizard = new ErtSetUpWizard;