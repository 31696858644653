/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class CrewScheduleTimeOffRequests {

//########################################################################################################
// Class variables

  //########################################################################################################
  // always called
  init() {}
    //window.common.debug('Init called from window.crew_schedule_time_off_requests')
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/index
  index() {
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  new() {
    // window.common.debug( "New called from window.crew_schedule_time_off_requests" )
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  edit() {
    // window.common.debug( "Edit called from window.crew_schedule_time_off_requests" )
  }
  //########################################################################################################
}
//########################################################################################################
window.crew_schedule_time_off_requests = new CrewScheduleTimeOffRequests;