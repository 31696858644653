/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;

class ErtTypes {

  //########################################################################################################
  // always called
  init() {
    //window.common.debug('Init called from window.ert_category_items');
  }
  //########################################################################################################

  //########################################################################################################
  index() {
    var ert_types_index_table = $('#data-table-ert_types_index').DataTable({
      fixedHeader: true,
      paging: false,
      responsive: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      ert_types_index_table.destroy();
      //window.common.debug('window.ert_types cleaning up tables');
      //alert("cleanup in progress");
    });
  }
  //########################################################################################################

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#new_link',
          intro: '<b>New ERT Type</b> <br> Click this button to add a new ERT Type. ',
          position: 'bottom'
        },
        // {
        //   element: '#config_wizard',
        //   intro: '<b>Configuration Wizard</b> <br> Click this button to start or resume the ERT Wizard. ',
        //   position: 'bottom'
        // },
        {
          element: '#data-table-ert_types_index',
          intro: '<b>ERT Types</b> <br> Your agencies ERT Types. ',
          position: 'top'
        }
      ]
    });

    intro.start();
    // document.querySelector('#ert_types_new_edit_help')
    //   .addEventListener('click', function (e) {
    //     e.preventDefault();
    //     intro.start();
    // });
  }
  //########################################################################################################

  //########################################################################################################
  new() {

  }
  //########################################################################################################

  //########################################################################################################
  edit() {

  }
  //########################################################################################################

  //########################################################################################################
  new_edit_help() {
    // window.common.debug('called new_edit_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#ert_name_div',
          intro: '<b>ERT Type Name</b> <br> The name of this ERT Type. ',
          position: 'right'
        },
        {
          element: '#ert_comment_div',
          intro: '<b>ERT Comment</b> <br> This comment will be displayed to the member when they start to open ' +
            'a new ERT for this ERT Type . ',
          position: 'right'
        },
        {
          element: '#ert_enabled_div',
          intro: '<b>ERT Enabled</b> <br> Enable or disable the ability to open new ERTs for this ERT Type. ',
          position: 'right'
        },
        {
          element: '#ert_subscriptions_div',
          intro: '<b>Restricted ERT</b> <br> When this is enabled members will have to be subscribed to this ' +
            'ERT Type in order to view ERTs for this ERT Type.  A member will not be able to subscribe to this ' +
            'ERT Type instead an ERT Admin will need to subscribe members. ',
          position: 'right'
        },
        {
          element: '#ert_vehicle_link_div',
          intro: '<b>Linked to Vehicles </b> <br> When this is enabled a member will be required to select a vehicle ' +
            'when they create a new ERT. ',
          position: 'right'
        },
        {
          element: '#ert_actions_div',
          intro: '<b>Actions</b> <br> Tasks that are assigned to members when an ERT is created. An example ERT ' +
            'Type would be New Member and an action would be to create an ifire account for the new member.  Actions do ' +
            'not integrate into the Inventory System.',
          position: 'right'
        },
        {
          element: '#ert_categories_div',
          intro: '<b>Categories</b> <br> Great for EMS or office supplies; basically consumable items that need to be routinely replaced. ' +
            'An example ERT Type could by EMS with a Category called Bandaging which could have Items like Gauze or Dressings. ' +
            'For new ERTs, a user will be prompted to fill out a table ' +
            'with the quantities needed for each item.  Categories can be integrated into the Inventory System.',
          position: 'right'
        },
        {
          element: '#ert_subtypes_div',
          intro: '<b>Subtypes</b> <br> Great for grouping equipment; non-consumable items.  An example ERT Type could be Radio Equipment with ' +
            'Subtypes like Portable or Portable Battery.  Subtypes can be integrated into the Inventory System.',
          position: 'right'
        }
      ]
    });

    intro.start();
    // document.querySelector('#ert_types_new_edit_help')
    //   .addEventListener('click', function (e) {
    //     e.preventDefault();
    //     intro.start();
    // });
  }
  //########################################################################################################

  //########################################################################################################
  subscriptions() {
    var ert_alerts_table = $('#data-table-ert_alerts').DataTable({
      fixedHeader: true,
      paging: false,
      responsive: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      ert_alerts_table.destroy();
      //window.common.debug('window.ert_types cleaning up tables');
      //alert("cleanup in progress");
    });
  }
  //########################################################################################################

  //########################################################################################################
  subscription_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel. '
        },
        {
          intro: '<b>ERT Subscription Help</b> <br> Subscriptions are optional, except when the ERT Type is Restricted. ' +
            'A member must be subscribed to the Restricted ERT Type in order to view Restricted ERTs.'
        },
        {
          element: '#ert_alert_member_div',
          intro: '<b>Member</b> <br> Select the Member that you wish to subscribe to this ERT Type. ',
          position: 'bottom'
        },
        {
          element: '#ert_alert_facility_div',
          intro: '<b>Facility</b> <br> Select the Facility that you wish to subscribe the member to.',
          position: 'bottom'
        },
        {
          element: '#data-table-ert_alerts',
          intro: '<b>Subscriptions</b> <br> The current ERT Subscriptions. ',
          position: 'top'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

  //########################################################################################################
  all_defaults() {
    // $('#data-table').DataTable({
    //   "bPaginate": false,
    //   "bFilter": false,
    //   "bInfo": false
    // });
  }
  //########################################################################################################
}

//########################################################################################################
window.ert_types = new ErtTypes;