/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Settings {

  //########################################################################################################
  // Class variables

  //########################################################################################################
  // always called
  init() {}
  //########################################################################################################


  index() {}
  //########################################################################################################

  email() {
    $("#use_gmail").on("change", window.settings.toggle_gmail);
    window.settings.toggle_gmail();
    $("#custom_mail").on("change", window.settings.custom_mail);
    window.settings.custom_mail();
  }

  //########################################################################################################
  custom_mail() {
    const custom_mail = $("#custom_mail").is(':checked')
    if (custom_mail) {
      // alert("checked");
      $("#custom_email_settings").show();
      const url = "/manager/settings/email_switch"
      $.ajax({
        type: "POST",
        url,
        data: { custom_mail: custom_mail }
      });
    } else {
      // alert("unchecked");
      $("#custom_email_settings").hide();
      const url = "/manager/settings/email_switch"
      $.ajax({
        type: "POST",
        url,
        data: { custom_mail: custom_mail }
      });
    }
  }

  //########################################################################################################
  toggle_gmail() {
    const gmail = $("#use_gmail").is(':checked');
    if (gmail) {
      $("#mail-settings-other").hide();
      $("#mail-settings-gmail").show();
      $("#mail-settings-google-calendar").show();
    } else {
      $("#mail-settings-other").show();
      $("#mail-settings-gmail").hide();
      $("#mail-settings-google-calendar").hide();
    }
  }
}


//########################################################################################################
window.settings = new Settings;