/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtSearch {
  //########################################################################################################
  // always called
  init() { }
  //window.common.debug('Init called from window.ert_search')
  //########################################################################################################

  //########################################################################################################
  // /manager/ert_search/edit
  edit() { }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/ert_search/index
  index() {
    //window.common.debug( "ert_search index" )
    // $('#data-table-erts').dataTable({
    //   "bPaginate": false,
    //   "bFilter": false,
    //   "bInfo": false
    // });
  }
  //########################################################################################################

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          intro: '<b>ERT Search Help</b> <br> Please note that the larger query the longer the server will take to ' +
            'provide the Search Results Table.'
        },
        {
          element: '#ert_status_div',
          intro: '<b>ERT Status </b> <br> Select the ERT Status you wish to query. "Any Status" will show all ERT States.',
          position: 'bottom'
        },
        {
          element: '#ert_type_div',
          intro: '<b>ERT Type</b> <br> Select the ERT Type you wish to query. "Any ERT Type" will show all ERT Types.',
          position: 'bottom'
        },
        {
          element: '#facility_div',
          intro: '<b>Facility</b> <br> Select the Facility you wish to query. "Any Facilty" will show ERTs for all Facilities.',
          position: 'bottom'
        },
        {
          element: '#vehicle_div',
          intro: '<b>Vehicle</b> <br> Select the Vehicle you wish to query. "Any Vehicle" will show ERTs for all Vehicles.',
          position: 'bottom'
        },
        {
          element: '#assigned_to_div',
          intro: '<b>Assigned To</b> <br> Select a Member that you wish to see the ERTs assigned to them. "Any Member" will show ERTs for all Members.',
          position: 'top'
        },
        {
          element: '#opened_by_div',
          intro: '<b>Opened By</b> <br> Select a Member that you wish to see the ERTs that they opened. "Any Member" will show ERTs for all Members. ',
          position: 'top'
        },
        {
          element: '#key_word_search_div',
          intro: '<b>Key Word Search</b> <br> This will search for key words in ERT Titles, Descriptions, and Comments.',
          position: 'top'
        },
        {
          element: '#submit_button',
          intro: '<b>Submit Button</b> <br> Click on the submit button to display the list of ERTs.',
          position: 'top'
        },
        {
          element: '#projected_results_div',
          intro: '<b>Search Results Summary</b> <br> This area will show you the search critera and the total ERTs that will be displayed.',
          position: 'bottom'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/ert_search/update
  update() {
    var ert_search_table = $('#data-table-erts').DataTable({
      ajax: {
        type: "GET",
        url: "/manager/ert_search/get_ert_data",
        data: function (d) {
          if ($('#phase_id').length) {
            d.phase_id = $('#phase_id').val();
            d.ert_type_id = $('#ert_type_id').val();
            d.facility_id = $('#facility_id').val();
            d.vehicle_id = $('#vehicle_id').val();
            d.assigned_to_id = $('#assigned_to_id').val();
            d.originator_id = $('#originator_id').val();
            d.opened_on_symbol = $("#opened_on_symbol").val();
            d.opened_on = $("#opened_on").prop("value");
            d.opened_on_end = $("#opened_on_end").prop("value");
            d.key_word = $("#key_word").prop("value");
          } else {
            d.phase_id = "";
            d.ert_type_id = "";
            d.facility_id = "";
            d.vehicle_id = "";
            d.assigned_to_id = "";
            d.originator_id = "";
            d.opened_on_symbol = "";
            d.opened_on = "";
            d.opened_on_end = "";
            d.key_word = "";
          }
        }
      },
      deferRender: true,
      dom: "<'row'<'col-sm-12 pb-3'B>>" +
        "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        'excelHtml5',
        'csvHtml5',
        'pdfHtml5',
        'print'
      ],
      columns: [
        {
          data: 'placeholder',
          render: function (data, type) {
            return '';
          }
        },
        {
          data: 'ert',
          render: function (data, type) {
            return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
          }
        },
        {
          data: 'title',
          defaultContent: ""
        },
        { data: 'type' },
        { data: 'subtype' },
        { data: 'vehicle' },
        {
          data: {
            _: "opened_on.display",
            sort: "opened_on.timestamp"
          }
        },
        { data: 'opened_by' },
        { data: 'status' },
        { data: 'assigned_to' },
        {
          data: 'comment',
          render: function (data, type) {
            var the_comment = "No Comments"
            if (!!data.member) {
              the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
            }
            return the_comment;
          }
        }
      ],
      fixedHeader: true,
      language: {
        emptyTable: "There are no ERTs that meet your search criteria."
      },
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-12" style="white-space: normal;">' +
                '<span>' + col.data + '</span> ' +
                '</div> </div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      stateSave: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      ert_search_table.destroy();
    });
  }
}
//########################################################################################################

//########################################################################################################
window.ert_search = new ErtSearch;