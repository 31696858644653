/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class EmailTemplates {
  init() {
    return root.common.debug('Email Templates init');
  }

  new() {
    let cm_email_template;
    return cm_email_template = CodeMirror.fromTextArea($("#email_template_template")[0], window.common.code_mirror_defaults() );
  }

  edit() {
    let cm_email_template;
    return cm_email_template = CodeMirror.fromTextArea($("#email_template_template")[0], window.common.code_mirror_defaults() );
  }
}


root.email_templates = new EmailTemplates;