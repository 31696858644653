/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Articles {

  //########################################################################################################
  // always called
  init() {}
  //########################################################################################################


  new() {
    window.common.debug('Creating article, will invoke edit');
    return this.edit();
  }

  //########################################################################################################
  // edit
  edit() {
    window.common.debug('Editing an article.');
    return window.common.set_media_callback(window.articles.update_photo);
  }
  //
  //########################################################################################################

  //########################################################################################################
  // index
  update_photo(id, aspect_url) {
    $("#article-photo").attr('src', aspect_url);
    $("#article-photo-id").val(id);
    $("#article-photo-remove").removeClass("hide");
    return window.common.debug("Received " + aspect_url + " to update_photo for id: "+id);
  }
}
  //########################################################################################################


window.articles = new Articles;