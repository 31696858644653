/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import { Calendar } from '@fullcalendar/core';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var Calendars = (function () {
  let the_calendar = undefined;
  Calendars = class Calendars {
    static initClass() {

      //########################################################################################################
      // Class variables
    }

    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.calendars')
    //########################################################################################################


    //########################################################################################################
    // edit
    edit() { }
    //
    //########################################################################################################

    //########################################################################################################
    // index
    index() { }
    //
    //########################################################################################################


    //########################################################################################################
    // set_calendar
    set_calendar(anCalendar) {
      //window.common.debug( anCalendar )
      //return window.calendars.calendar = anCalendar;
    }
    //
    //########################################################################################################


    //########################################################################################################
    // new
    new() { }
    //
    //########################################################################################################


    //########################################################################################################
    // show
    show() {
      window.calendars.create_show_calendar();
    }
  
    //########################################################################################################
    create_show_calendar() {
      //window.common.debug('Show called from window.calendars')
      var calendarEl = document.getElementById('calendar');

      window.calendars.the_calendar = new Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        buttonText: {
          day: 'Day',
          month: 'Month',
          today: 'Today',
          week: 'Week'
        },
        contentHeight: "auto",
        expandRows: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        plugins: [dayGridPlugin, interactionPlugin],
        editable: true,
        droppable: true, // this allows things to be dropped onto the calendar !!!
        eventClick: function(info) {
          // window.common.debug('Edit Event ' + info.event.title + " | " + info.event.id)
          const url = "/manager/calendar_events/" + info.event.id;
          // window.common.debug(url);
          // const params = { id: info.id  };
          return $.get(url);
        },
        eventDrop(event, dayDelta, minuteDelta, allDay, revertFunc, jsEvent, ui, view) {
          const url = "/manager/calendar_events/" + event.id;
          return $.ajax({
            type: "PUT",
            url,
            data: { date_start: event.start, date_end: event.end, allDay }
          });
        },
        eventResize(event, dayDelta, minuteDelta, revertFunc, jsEvent, ui, view) {
          const url = "/manager/calendar_events/" + event.id;
          return $.ajax({
            type: "PUT",
            url,
            data: { date_start: event.start, date_end: event.end }
          });
        },
        events: {
          url: '/manager/calendar_events/load_events',
          extraParams: function () { // a function that returns an object
            return {
              calendar_id: $("#calendar_id").val()
            };
          }
        },
        selectable: true,
        select: function (info) {
          //window.common.debug('New Event ' + start + " | " + end)
          const calendar_id = $("#calendar_id").val();
          const params = { start_date: info.start, end_date: info.start, allDay: info.allDay, calendar_id: calendar_id  };
          return $.get("/manager/calendar_events/new", params);
          // return $.ajax({
          //   type: "GET",
          //   url: "/manager/calendar_events/new",
          //   data: { start_date: start, end_date: end, allDay, calendar_id: calendar_id }
          // });
        }
      });
      window.calendars.the_calendar.render();
    }

    //########################################################################################################
    refetch_calendar() {
      if (window.calendars.the_calendar !== undefined) {
        // alert("the_shift_calendar update");
        window.calendars.the_calendar.refetchResources();
        window.calendars.the_calendar.refetchEvents();
      } else {
        // alert("the_shift_calendar is undefined");
      }
    }

    //########################################################################################################
    calendar_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#calendar',
            intro: '<b>Calendar </b> <br> Your agency can have more than one calendar.  This can help your members find ' +
              'Calendar Events more quickly.',
            position: 'top'
          },
          {
            element: '#calendar',
            intro: '<b>Calendar </b> <br> You must have permission to add events to the calendar.',
            position: 'top'
          },
          {
            element: '#calendar',
            intro: '<b>Calendar </b> <br> To add an event click on the date for which you wish to add an event.',
            position: 'top'
          },
          {
            element: '#calendar',
            intro: '<b>Calendar </b> <br> All users will be able to see approved Calendar Events and you will also ' +
              'be able to see the events that you created that are awaiting approval. ',
            position: 'top'
          },
          {
            element: '#key_div',
            intro: '<b>Key </b> <br> Each Event Type can be given a unique color to help identify related events. ',
            position: 'left'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    calendar_overview() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Calendar Help</b> <br> The Calendar app is used to post Events and Sign-up Sheets.'
          },
          {
            intro: '<b>Calendar Navigation </b> <br> The Calendar Navigation links will ' +
              'vary from member to member due to each member\'s account settings. ' +
              'Member accounts can even be set to not even show the Calendar.'
          },
          {
            intro: '<b>Manage Calendars</b> <br> This is an admin page used to manage the Calendars.'
          },
          {
            intro: '<b>Manage Events</b> <br> Member accounts that can create or manage Calendar Events will have access to this page.'
          },
          {
            intro: '<b>Manage Event Types</b> <br> This is an admin page used to manage the Event Types.'
          },
          {
            intro: '<b>Calendars</b> <br> The list of active Calendars will be shown to users who have access to the Calendar app.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################


    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#new_calendar_div',
            intro: '<b>Create Calendar </b> <br> Click here to create a new calendar.',
            position: 'top'
          },
          {
            element: '#calendar_table',
            intro: '<b>Calendar </b> <br> Your agency can have more than one calendar.  This can help your members find ' +
              'Calendar Events more quickly.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    new_edit_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#title_div',
            intro: '<b>Calendar Title </b> <br> Enter the name for the Calendar.',
            position: 'top'
          },
          {
            element: '#is_public_div',
            intro: '<b>Public Calendar? </b> <br> You can have any calendar show up on your public facing website. ',
            position: 'top'
          },
          {
            element: '#is_enabled_div',
            intro: '<b>Calendar Enabled? </b> <br> You can enable or disable any calendar at any time. ',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################


  };
  Calendars.initClass();
  return Calendars;
})();
//
//########################################################################################################

//########################################################################################################
window.calendars = new Calendars;