import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtSubtypes {

    //########################################################################################################
    // always called
    init() {
        //window.common.debug('Init called from window.ert_category_items');
    }
    //########################################################################################################

    //########################################################################################################
    index() {
        var url = window.location.search;
        var id = url.substring(url.lastIndexOf('=') + 1);
        console.log(url);
        console.log(id);
        var the_path = '/manager/ert_subtypes/get_subtypes/'

        var subtypes_table = $('#data-table-subtypes').DataTable({
            ajax: {
                url: the_path,
                data: { "the_ert_type_id": id }
            },
            columns: [
                { data: 'ert_subtype', },
                {
                    className: "dt-center",
                    data: {
                        sort: 'is_enabled'
                    },
                    ClassName: 'dt-center',
                    render: function (data, type) {
                        var the_action = data.is_enabled;
                        if (data.is_enabled === "true") {
                            the_action = '<i class="fas fa-check"></i>';
                        } else {
                            the_action = '<i class="fas fa-times"></i>';
                        }
                        return type === "display" ? the_action : data;
                    }
                },
                {
                    data: 'actions',
                    render: function (data, type) {
                        var the_action = ""
                        the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/ert_subtypes/' + data.the_subtype_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
                        return the_action;
                    }
                }
            ],
            fixedHeader: true,
            paging: false,
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                                '</div>' +
                                '<div class="row">' +
                                '<span>' + col.data + '</span> ' +
                                '</div> <br>' :
                                '';
                        }).join('');

                        return data ?
                            data :
                            false;
                    }
                }
            },
            searchPane: false,
            select: true
        });

        document.addEventListener('turbolinks:load', function () {
            subtypes_table.destroy();
        });
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
        // window.common.debug('called index_help');
        var intro = introJs();
        intro.setOptions({
            showStepNumbers: false,
            steps: [
                {
                    intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
                },
                {
                    element: '#return_link',
                    intro: '<b>Return</b> <br> Return to the ERT Types page. ',
                    position: 'bottom'
                },
                {
                    element: '#new_ert_subtype_link',
                    intro: '<b>Add New ERT Subtype</b> <br> Click here to add a new ERT Subtype. ' +
                        'Note that ERT Subtypes cannot be deleted.',
                    position: 'bottom'
                },
                {
                    element: '#data-table-subtypes',
                    intro: '<b>Subtypes</b> <br> Here is a listing of the ERT Subtypes for this ERT Type. ',
                    position: 'bottom'
                },
                {
                    intro: '<b>ERT Subtypes</b> <br> ' +
                        'ERT Subtypes can be renamed or enabled/discabled at any time.'
                }
            ]
        });

        intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    new() {

    }
    //########################################################################################################

    //########################################################################################################
    edit() {

    }
    //########################################################################################################

}

//########################################################################################################
window.ert_subtypes = new ErtSubtypes;