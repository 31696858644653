/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class CrewScheduleTemplates {

  //########################################################################################################
  // Class variables

  //########################################################################################################
  // always called
  init() {
    return window.crew_schedule_templates.row_count = 0;
  }
    //window.common.debug('Init called from window.crew_schedule_templates')
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/index
  index() {
    return $('#data-table-crew_schedules').dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
  //########################################################################################################

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
        },
        {
          intro: '<b>Schedule Templates </b> <br> Schedule Templates are used to create a shift that you ' +
            'can reuse over and over again.  The templates themselves will not show up on a calendar.'
        },
        {
          element: '#new_template_div',
          intro: '<b>Add a New Schedule Template </b> <br> Click here to create a new Schedule Template.',
          position: 'right'
        },
        {
          element: '#data-table-crew_schedules',
          intro: '<b>Schedule Templates </b> <br> Here are your Schedule Templates.',
          position: 'top'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  new() {}
    //window.common.debug( "New" )
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  edit() {
    //window.common.debug( "Edit" )
    return $('#data-table-crew_schedule_positions').dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
}
  //########################################################################################################
//########################################################################################################
window.crew_schedule_templates = new CrewScheduleTemplates;