/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class PoAccounts {
  //########################################################################################################
  // always called
  init() {}
    //window.common.debug('Init called from window.po_accounts')
  //########################################################################################################

  //########################################################################################################
  // /manager/po_accounts/index
  index() {
    //window.common.debug('window.po_accounts.show')
    return $('#data-table').dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/po_accounts/show
  show() {
    $('#po_account_approver_po_acct_approver_id').select2();
    window.po_accounts.set_account_approvers_table();

    $('#po_account_access_po_acct_accessor_id').select2();
    window.po_accounts.set_account_access_table();
  }

  //########################################################################################################
  // /manager/po_accounts/access_check
  access_check() {
    //window.common.debug('window.po_accounts.show')
    // return $('#data-table').dataTable({
    //   "pageLength": 25
    // });
    window.po_accounts.set_access_check_table();
  }

  //########################################################################################################
  clone_account_access_form() {
    $('#account_approver_from').select2();
    $('#account_approver_to').select2();    
  }

  //########################################################################################################
  set_account_approvers_table() {
      var id = $("#po_account_id").val();
      var the_path = '/manager/po_accounts/get_account_approvers'
      $('#data-table-account_approvers').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {data: 'member' },
          { data: 'authorized_by' },
          {
            data: {
              _: "authorized_on.display",
              sort: "authorized_on.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-mini btn-danger" data-remote="true" rel="nofollow" data-method="delete" href="/manager/po_account_approvers/' + data.id + '">' +
                '<i class="fas fa-trash-alt fa-lg" aria-hidden="true"></i> Delete </a> ';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no Account Approvers for this PO Vendor."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      // $('#awaiting_approval-tab').on('shown.bs.tab', function (e) {
      //   $($.fn.dataTable.tables(true)).DataTable()
      //     .columns.adjust()
      //     .responsive.recalc();
      // });    
  }

  //########################################################################################################
  set_account_access_table() {
      var id = $("#po_account_id").val();
      var the_path = '/manager/po_accounts/get_account_access'
      $('#data-table-account_acess').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {data: 'member' },
          { data: 'authorized_by' },
          {
            data: {
              _: "authorized_on.display",
              sort: "authorized_on.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-mini btn-danger" data-remote="true" rel="nofollow" data-method="delete" href="/manager/po_account_accesses/' + data.id + '">' +
                '<i class="fas fa-trash-alt fa-lg" aria-hidden="true"></i> Delete </a> ';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There is no Account Access for this PO Vendor."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      // $('#awaiting_approval-tab').on('shown.bs.tab', function (e) {
      //   $($.fn.dataTable.tables(true)).DataTable()
      //     .columns.adjust()
      //     .responsive.recalc();
      // });    
  }

  //########################################################################################################
  set_access_check_table() {
      $('#data-table-access_check').DataTable({
        ajax: '/manager/po_accounts/get_access_check',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po_account_name',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/po_accounts/' + data.po_account_id + '" >' + data.po_accont_name + '</a>';
            }
          },
          { data: 'description' },
          {
            data: 'is_active',
            render: function (data, type) {
                var the_action = "";
                if (type == "display") {
                    if (data == "true") {
                        the_action += '<i class="fas fa-check green" aria-hidden="true"></i>';
                    } else {
                        the_action += '<i class="fas fa-times red" aria-hidden="true"></i>';
                    }
                } else if (type == "sort") {
                    if (data == "true") {
                        the_action = 1;
                    } else {
                        the_action = 0;
                    }
                }
                return the_action;
            }
          },
          {
            data: 'can_approve',
            render: function (data, type) {
              var the_content = "<div>" + data + "</div>";
              return the_content
            }
          },
          { data: 'can_access' }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no PO Accounts."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });    
  }

  //########################################################################################################
  show_help() {
    // window.common.debug('called show_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#po_account_div',
          intro: '<b>PO Vendor</b> <br> This area displays the PO Account information and has a button to ' +
            'edit the PO Account. ',
          position: 'bottom'
        },
        {
          element: '#account_approvers',
          intro: '<b>Account Approvers</b> <br> This is the list of members that can approve PO line items associated with this PO Account',
          position: 'bottom'
        },
        {
          element: '#account_access',
          intro: '<b>Account Access</b> <br> This is the list of members that can apply this PO Account to a PO line item. ',
          position: 'bottom'
        }
      ]
    });

    intro.start();
  }

  //########################################################################################################
  clone_account_access_help() {
    // window.common.debug('called show_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#po_account_approver_list',
          intro: '<b>Clone PO Account Access</b> <br> Use this form to clone PO Account Access and/or PO Account ' +
            'approval from one member to another. ',
          position: 'bottom'
        },
        {
          element: '#account_approver_from_div',
          intro: '<b>Member to Clone</b> <br> This is the member that you want to Clone.',
          position: 'bottom'
        },
        {
          element: '#account_approver_to_div',
          intro: '<b>Member to Gain Access</b> <br> This is the member that will. ',
          position: 'bottom'
        },
        {
          element: '#clone_po_approval_div',
          intro: '<b>Clone PO Account Approval</b> <br> This grants the ability to approve PO Line Items with these PO Accounts.',
          position: 'bottom'
        },
        {
          element: '#clone_po_access_div',
          intro: '<b>Clone PO Account Access</b> <br> This grants the ability to assign the PO Account to PO Line Items. ',
          position: 'bottom'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

}
  //
  //########################################################################################################

//########################################################################################################
window.po_accounts = new PoAccounts;