/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtCategoryItems {


  //########################################################################################################
  // always called
  init() { }
  //window.common.debug('Init called from window.ert_category_items')
  //########################################################################################################


  //########################################################################################################
  // /manager/ert_category_items/edit
  edit() { }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/ert_category_items/index
  index() {
    //window.common.debug( "ert_category_items index" )
    // $('#data-table').dataTable({
    //   "bPaginate": false,
    //   "bFilter": false,
    //   "bInfo": false
    // });
  }
}
//########################################################################################################

//########################################################################################################
window.ert_category_items = new ErtCategoryItems;