/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var Erts = (function () {
  let ert_types = undefined;
  let ert_subtypes = undefined;
  let ert_cat_items = undefined;
  let ert_categories = undefined;
  let theErt = undefined;
  Erts = class Erts {
    static initClass() {

      //########################################################################################################
      // Class variables
      ert_types = [];
      ert_subtypes = [];
      ert_cat_items = [];
      ert_categories = [];
      theErt = null;
    }

    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.erts')
    //########################################################################################################


    //########################################################################################################
    // always called
    create() {
      //window.common.debug('Create called from window.erts')
      return window.erts.new();
    }
    //########################################################################################################


    //########################################################################################################
    // /manager/erts/edit
    edit() { }
    //
    //########################################################################################################

    //########################################################################################################
    edit_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: "<b>ERT Help Overview</b> <br> All fields in red must either be filled out or an option must selected."
          },
          {
            element: '#ert_type_div',
            intro: '<b>ERT Type</b> <br> The ERT Type cannot be changed.  If the ERT Type is incorrect you will need ' +
              'to close this ERT and open a new one with the correct ERT Type.',
            position: 'bottom'
          },
          {
            element: '#ert_status_div',
            intro: '<b>ERT Status</b> <br> The ERT Status types is defined by the system and cannot be changed. ',
            position: 'bottom'
          },
          {
            element: '#problem_title_div',
            intro: '<b>Problem Title </b> <br> You will need to have admin privileges in order to edit the ERT Problem Title',
            position: 'bottom'
          },
          {
            element: '#problem_desc_div',
            intro: '<b>Problem Description </b> <br> You will need to have admin privileges in order to edit the ERT Problem Description',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {
      window.erts.set_assigned_erts_table();
      window.erts.set_watch_list_table();
      window.erts.set_assigned_actions_table();
      window.erts.set_authored_erts_table();
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#spreadsheets_div',
            intro: '<b>ERT Dashboard (1/2) </b> <br> The ERT Dashboard provides you with with the ability to quickly look up an ERT and it also has ' +
              ' lists of the ERTs that are tailered to each user.',
            position: 'bottom'
          },
          {
            element: '#spreadsheets_div',
            intro: '<b>ERT Dashboard (2/2) </b> <br> Each ERT table has the ability to export the tables data to Excel, CSV, PDF, or print.',
            position: 'bottom'
          },
          {
            element: '#ert_number_search',
            intro: '<b>ERT Quick Search</b> <br> If you know an ERT number you can view it by entering it here and clicking the Submit button. ',
            position: 'bottom'
          },
          {
            element: '#assigned_erts-tab',
            intro: '<b>Assigned ERTs</b> <br> This table contains the open ERTs that are currently assigned to you.',
            position: 'bottom'
          },
          {
            element: '#ert_watch_list-tab',
            intro: '<b>ERT Watch List</b> <br> This table contains a list of the ERTs that you added to your watch list, regardless of the ERT status.',
            position: 'bottom'
          },
          {
            element: '#assigned_action-tab',
            intro: '<b>Assigned Actions</b> <br> This table contains the open ERT actions that are assigned to you.',
            position: 'bottom'
          },
          {
            element: '#open_authored_erts-tab',
            intro: '<b>Open Authored ERTs</b> <br> This table contains the list of open ERTs that you created.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    index_overview() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>ERT Help</b> <br> ERT is short for <b>E</b>lectronic <b>R</b>epair <b>T</b>icket.  ERTs are used to track actionable "tickets" ' +
              ' for your agency and has evolved to handle more than just "repair" tickets.  ERTs can be used to track new members ' +
              ' or to submit a request for new supplies.'
          },
          {
            intro: '<b>ERT Navigation </b> <br> The ERT Navigation links will vary from member to member due to each member\'s account settings. ' +
              'Member accounts can even be set to not even show the ERT System.'
          },
          {
            intro: '<b>ERT Dashboard</b> <br> Any member with any amount of ERT access will see the ERT Dashboard.'
          },
          {
            intro: '<b>New ERT</b> <br> This page is used to create new ERTs. ' +
              'This capability requires account permission to access.  If you do not see the link then you do not have permission to create new ERTs.'
          },
          {
            intro: '<b>ERT Alerts</b> <br> ERT Alerts are used to subscribe to receive emails for specific ERT Types when they are opened, updated, ' +
              'or closed.  Ask your agencies ERT admin if you should subscribe to any specific ERT Types.'
          },
          {
            intro: '<b>Facility ERTs</b> <br> This page will display all of your facilities open ERTs if you are assigned to a facility.'
          },
          {
            intro: '<b>Search</b> <br> This page provides the capabilities to perform advanced ERT searches.'
          },
          {
            intro: '<b>Settings</b> <br> This page provides the capabilities to add and modify ERT Types. ' +
              'This capability requires account permission to access.  If you do not see the link then you do not have ERT Admin permission.'
          },
          {
            intro: '<b>Admin</b> <br> This page provides the capabilities to perform bulk ERT reassignments. ' +
              'This capability requires account permission to access.  If you do not see the link then you do not have ERT Admin permission.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() {

      $('#ert_ert_type_id').on('change', this.changed_ert_type);
      $('#ert_ert_type_id').select2();

      $('#ert_ert_subtype_id').select2();

      $('#ert_ert_category_id').on('change', this.changed_ert_category);
      $('#ert_ert_category_id').select2();
      $('#data-table').DataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        "aoColumns": [
          { "bSortable": false }, { "bSortable": false }
        ]
      });

      $('#ert_facility_id').select2();
      $('#ert_vehicle_id').select2();

      if (window.erts.theErt.ert_type_id != null) {
        // window.common.debug( "Update ERT Settings, ERT Type selected" )
        let laClickedElem = $('#ert_ert_type_id').prop("selectedIndex");
        //window.common.debug( laClickedElem )
        //window.common.debug( window.erts.ert_types[laClickedElem-1] )
        window.erts.set_ert_form(laClickedElem - 1);
        $('#ert_ert_subtype_id').val(window.erts.theErt.ert_subtype_id);
        $('#ert_ert_category_id').val(window.erts.theErt.ert_category_id);
        laClickedElem = $('#ert_ert_category_id').prop("value");
        return window.erts.set_ert_category(laClickedElem);
      } else {
        //window.common.debug( "No need to update ERT Settings, ERT Type not selected" )
        $('#ert_vehicle_header').hide();
        $('#ert_vehicle_oos_header').hide();
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').hide();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();
        return $('#ert_comment_header').hide();
      }
    }
    //########################################################################################################

    //########################################################################################################
    new_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: "<b>ERT Help</b> <br> All fields in red must either be filled out or an option must selected."
          },
          {
            element: '#select_ert_type_div',
            intro: '<b>ERT Type</b> <br> The ERT Type is the main category for the ERT you are about to write. ' +
              'Some ERT Types have a Subtype, some have a Category, and some have neither. ',
            position: 'bottom'
          },
          {
            element: '#ert_sub_types_dummy_header',
            intro: '<b>Subtypes</b> <br> Great for grouping equipment; non-consumable items.  An example ERT Type could be Radio Equipment with ' +
              'Subtypes like Portable or Portable Battery.',
            position: 'bottom'
          },
          {
            element: '#ert_categories_dummy_header',
            intro: '<b>Categories (1/2)</b> <br> Great for EMS or office supplies; basically consumable items that need to be routinely replaced. ' +
              'An example ERT Type could by EMS with a Category called Bandaging which could have Items like Gauze or Dressings. ',
            position: 'bottom'
          },
          {
            element: '#ert_category_items_dummy_header',
            intro: '<b>Categories (2)</b> <br> ' +
              'For new ERTs, you will be prompted to fill out a table with the quantities needed for each consumable item.',
            position: 'bottom'
          },
          {
            element: '#ert_facility_header',
            intro: '<b>Facility</b> <br> The Facility associated with this ERT. ',
            position: 'top'
          },
          {
            element: '#ert_vehicle_dummy_header',
            intro: '<b>Vehicle</b> <br> Some ERTs are associated with a Vehicle. For those ERT Types you will be prompted to selecte a vehicle',
            position: 'top'
          },
          {
            element: '#problem_title_div',
            intro: '<b>Problem Title</b> <br> This is intended to be a short description of the problem. ',
            position: 'top'
          },
          {
            element: '#problem_desc_div',
            intro: '<b>Problem Description</b> <br> This is intended to provide more detailed information about the problem. ',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    show() {
      var url = window.location.pathname;
      var id = url.substring(url.lastIndexOf('/') + 1);
      // alert(id);
      var the_path = '/manager/ert_type_action_trackings/get_trackings/'

      $('#data-table-actions').DataTable({
        ajax: {
          url: the_path,
          data: { "the_ert": id }
        },
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        "ordering": false,
        columns: [
          { data: 'task_name' },
          { data: 'assigned_to' },
          {
            data: {
              _: "closed_on.display",
              sort: "closed_on.timestamp"
            }
          },
          { data: 'closed_by' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = "";
              if (!!data.permission) {
                the_action = '<table> <tr style="background: transparent;">';
                if (!!data.closed_on) {
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="put" data-remote="true" href="/manager/ert_type_action_trackings/' + data.tracking_id + '?update_action=reopen_action" rel="nofollow"><i class="fas fa-sync"></i> Re - Open</a > ';
                  the_action += '</td>';
                }
                else {
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="get" data-remote="true" href="/manager/ert_type_action_trackings/' + data.tracking_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="fas fa-user"></i> &nbsp; Reassign</a >';
                  the_action += '</td>';
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="put" data-remote="true" href="/manager/ert_type_action_trackings/' + data.tracking_id + '?update_action=close_action" rel="nofollow" style="display: inline-flex"><i class="fas fa-check"></i> &nbsp; Close</a >';
                  the_action += '</td>';
                }
                the_action += '</tr></table>';
              } else {
                // Hide the column if the user does not have permissions or if the ERT is closed
                $('#data-table-actions').DataTable().column(4).visible(false);
              }
              return the_action;
            }
          }
        ],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        }
      });

      the_path = '/manager/ert_category_item_trackings/get_trackings/'

      $('#data-table-items').DataTable({
        ajax: {
          url: the_path,
          data: { "the_ert": id }
        },
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        "ordering": false,
        columns: [
          //{ data: 'inventory_number' },
          { data: 'item_description' },
          { data: 'quantity' },
          {
            data: {
              _: "closed_on.display",
              sort: "closed_on.timestamp"
            }
          },
          { data: 'closed_by' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (!!data.permission) {
                the_action = '<table> <tr style="background: transparent;">';
                if (!!data.closed_on) {
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="put" data-remote="true" href="/manager/ert_category_item_trackings/' + data.tracking_id + '?update_action=reopen_action" rel="nofollow"><i class="fas fa-sync"></i> Re - Open</a > ';
                  the_action += '</td>';
                }
                else {
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="get" data-remote="true" href="/manager/ert_category_item_trackings/' + data.tracking_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="fas fa-user"></i> &nbsp; Edit</a >';;
                  the_action += '</td>';
                  the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                  the_action += '<a class="btn btn-sm btn-danger" data-method="put" data-remote="true" href="/manager/ert_category_item_trackings/' + data.tracking_id + '?update_action=close_action" rel="nofollow" style="display: inline-flex"><i class="fas fa-check"></i> &nbsp; Close</a >';
                  the_action += '</td>';
                }
                the_action += '</tr></table>';
              } else {
                // Hide the column if the user does not have permissions or if the ERT is closed
                $('#data-table-items').DataTable().column(4).visible(false);
              }
              return the_action;
            }
          }
        ],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        }
      });

      $('#data-table-ert_viewed_by').DataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
      });
    }
    //
    //########################################################################################################

    //########################################################################################################
    erts_show_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            element: '#admin_edit_link',
            intro: '<b>ERT Edit </b> <br> Click here to Edit the ERT.  Be advised that your account will need to be given access to Edit ERTs.',
            position: 'bottom'
          },
          {
            element: '#ert_number_div',
            intro: '<b>ERT Number</b> <br> A unique ERT number is assigned to each ERT.  This number is incremented each time an ERT is created.',
            position: 'bottom'
          },
          {
            element: '#ert_tile_description_div',
            intro: '<b>ERT Title & Description</b> <br> You will find the ERT Title and Description here. ',
            position: 'bottom'
          },
          {
            element: '#ert_comments-tab',
            intro: '<b>ERT Comments</b> <br> Use the ERT Comments form to add new comments and to also close the ERT.  Be advised that your ' +
              'account will need to be given access in order to enter comments and also to close ERTs.',
            position: 'top'
          },
          {
            element: '#ert_changes-tab',
            intro: '<b>ERT Change Log</b> <br> All changes to the ERT (other than ERT Comments) will be logged here.',
            position: 'top'
          },
          {
            element: '#viewed_by-tab',
            intro: '<b>Viewed By</b> <br> Here you can see who has viewed the ERT and when the user has viewed the ERT. ',
            position: 'top'
          },
          {
            element: '#watch_list_button1',
            intro: '<b>ERT Watch List</b> <br> ' +
              'Use the ERT Watch List to maintain a focus list of ERTs.  The Watch List can be found on the main iFire Dashboard and on the ERT Dashboard.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    facility_erts() {
      $('#data-table-facility_erts').DataTable({
        ajax: '/manager/erts/get_facility_erts',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          {
            data: 'title',
            defaultContent: ""
          },
          { data: 'type' },
          { data: 'subtype' },
          { data: 'vehicle' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'opened_by' },
          { data: 'status' },
          { data: 'assigned_to' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no open ERTs for your assigned facility."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#assigned_action-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    ert_admin_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>ERT Admin Help</b> <br> The Administrative Functions give you the ability to reassign ' +
              'ERTs from one Member to another or reassign ERTs from one Vehicle to another.'
          },
          {
            element: '#bulk_member_reassign',
            intro: '<b>Bulk Member Reassign</b> <br> Use this feature to reassign ERTs from one user to another.',
            position: 'top'
          },
          {
            element: '#bulk_vehicle_reassign',
            intro: '<b>Bulk Vehicle Reassign</b> <br> Use this feature to reassign ERTs from one vehicle to another. ',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    facility_erts_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>Facility ERTs Help</b> <br> This table will not show up unless your account is assigned to a Facility.'
          },
          {
            element: '#data-table-facility_erts_wrapper',
            intro: '<b>Facility ERTs Table</b> <br> This table contains the open ERTs assigned to your Facility.',
            position: 'top'
          },
          {
            element: '#data-table-facility_erts_wrapper',
            intro: '<b>Facility ERTs Table</b> <br> You can export the contents of this table to Excel, CSV, PDF, or print. ',
            position: 'top'
          },
          {
            element: '#data-table-facility_erts_filter',
            intro: '<b>Facility ERTs Table</b> <br> The Search feature will only search the contents already being displayed in the table. ',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    reassign_member_erts_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>Reassign ERTs Help</b> <br> This Administrative Function give you the ability to reassign ' +
              'ERTs from one Member to another.  A preview of the list of ERTs will be shown under this form once ' +
              'the From/To Members are selected.'
          },
          {
            element: '#ert_status_div',
            intro: '<b>ERT Status</b> <br> Typically only open ERTs would need to be reassigned from one user to another. ' +
              'You can however leave this set to Any Status and all ERTs, regardless of their status, will be reaassigned.',
            position: 'bottom'
          },
          {
            element: '#ert_type_div',
            intro: '<b>ERT Type</b> <br> You can select to reassign all or ERTs of a specific type. ',
            position: 'bottom'
          },
          {
            element: '#from_member_div',
            intro: '<b>From Member</b> <br> Select the member that you want to reassign ERTs from. ',
            position: 'top'
          },
          {
            element: '#to_member_div',
            intro: '<b>To Member</b> <br> Select the member that you want to reassign ERTs to. ',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    reassign_vehicle_erts_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
          },
          {
            intro: '<b>Reassign ERTs Help</b> <br> This Administrative Function give you the ability to reassign ' +
              'ERTs from one vehicle to another.  A preview of the list of ERTs will be shown under this form once ' +
              'the From/To Vehicles are selected.'
          },
          {
            element: '#ert_status_div',
            intro: '<b>ERT Status</b> <br> Typically only open ERTs would need to be reassigned from one vehicle to another. ' +
              'You can however leave this set to Any Status and all ERTs, regardless of their status, will be reaassigned.',
            position: 'bottom'
          },
          {
            element: '#ert_type_div',
            intro: '<b>ERT Type</b> <br> You can select to reassign all or ERTs of a specific type. ',
            position: 'bottom'
          },
          {
            element: '#from_vehicle_div',
            intro: '<b>From Vehicle</b> <br> Select the vehicle that you want to reassign ERTs from. ',
            position: 'top'
          },
          {
            element: '#to_vehicle_div',
            intro: '<b>To Vehicle</b> <br> Select the vehicle that you want to reassign ERTs to. ',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_data(anErt) {
      //window.common.debug( anErt )
      return window.erts.theErt = anErt;
    }
    //########################################################################################################

    //########################################################################################################
    add_ert_types(anErtTypes) {
      return window.erts.ert_types = anErtTypes;
    }
    //window.common.debug(window.erts.ert_types[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_subtypes(anErtSubtypes) {
      return window.erts.ert_subtypes = anErtSubtypes;
    }
    //window.common.debug(window.erts.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_categories(anErtCategories) {
      return window.erts.ert_categories = anErtCategories;
    }
    //window.common.debug(window.erts.ert_categories[0])
    //########################################################################################################

    //########################################################################################################
    add_ert_cat_items(anErtCatItems) {
      return window.erts.ert_cat_items = anErtCatItems;
    }
    //window.common.debug(window.erts.ert_cat_items[0])
    //########################################################################################################

    //########################################################################################################
    changed_ert_type(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //window.common.debug( window.erts.ert_types[laClickedElem-1] )
      if (laClickedElem > 0) {
        window.erts.set_ert_form(laClickedElem - 1);
      } else {
        $('#ert_vehicle_header').hide();
        $('#ert_vehicle_oos_header').hide();
        $('#ert_comment_header').hide();
        $('#ert_category_items_header').hide();
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').hide();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();
      }
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_form(anErtType) {
      const lrErtType = window.erts.ert_types[anErtType];
      // Display the vehicles if the ERT type uses them
      if (lrErtType.has_vehicles) {
        $('#ert_vehicle_header').show();
      } else {
        $('#ert_vehicle_header').hide();
      }

      if (lrErtType.has_vehicles && !lrErtType.has_subtypes && !lrErtType.has_actions && !lrErtType.has_categories) {
        $('#ert_vehicle_oos_header').show();
      } else {
        $('#ert_vehicle_oos_header').hide();
      }

      if (lrErtType.comment) {
        $('#ert_comment_header').show();
        $('#ert_comment').html(lrErtType.comment);
      } else {
        $('#ert_comment_header').hide();
      }


      // Display the ERT Subtypes if the ERT type uses them
      if (lrErtType.has_subtypes) {
        $('#ert_sub_types_header').show();
        $('#ert_equipment_oos_header').show();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();

        // Clear and rebuild the sub_type option list based on the selected ERT type
        // Of course we need to add a default ERT Subtype too.
        $("#ert_ert_subtype_id").empty();
        $('#ert_ert_subtype_id').append($('<option>', {
          value: 0,
          text: "Select an ERT Subtype"
        }));


        return (() => {
          const result = [];
          for (let element of Array.from(window.erts.ert_subtypes)) {
            // Only add the ERT subtype if it belongs to the selected ERT type
            if (lrErtType._id === element.ert_type_id) {
              result.push($('#ert_ert_subtype_id').append($('<option>', {
                value: element._id,
                text: element.name
              })));
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      } else if (lrErtType.has_categories) {
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').show();
        $('#ert_categories_header').show();
        $('#ert_category_items_header').show();

        $('#ert_category_item_tracking_ert_category_id').prop('selectedIndex', 0);
        $('#ert_ert_category_id').empty();
        $('#cat_items_tbody').empty();
        $('#ert_ert_category_id').append($('<option>', {
          value: 0,
          text: "Please Select a Category"
        }));

        return (() => {
          const result1 = [];
          for (let element of Array.from(window.erts.ert_categories)) {
            // Only add the ERT Categories if it belongs to the selected ERT type
            if (lrErtType._id === element.ert_type_id) {
              result1.push($('#ert_ert_category_id').append($('<option>', {
                value: element._id,
                text: element.name
              })));
            } else {
              result1.push(undefined);
            }
          }
          return result1;
        })();

      } else {
        $('#ert_sub_types_header').hide();
        $('#ert_equipment_oos_header').hide();
        $('#ert_categories_header').hide();
        $('#ert_category_items_header').hide();
      }
    }
    //########################################################################################################

    //########################################################################################################
    changed_ert_category(event) {
      const laClickedElem = $(event.target).prop("value");
      return window.erts.set_ert_category(laClickedElem);
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_actions_table() {
      $('#data-table-assigned_actions').DataTable({
        ajax: '/manager/erts/get_assigned_actions',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          { data: 'action' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "You do not have any open ERT Tasks assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#assigned_action-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_erts_table() {
      //-----------------------------------------------------------------
      $('#data-table-assigned_erts').DataTable({
        ajax: '/manager/erts/get_assigned_erts',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          { data: 'type' },
          { data: 'subtype' },
          { data: 'vehicle' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'opened_by' },
          { data: 'status' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no ERTs assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#assigned_erts-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_authored_erts_table() {
      $('#data-table-authored_erts').DataTable({
        ajax: '/manager/erts/get_authored_erts',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          { data: 'type' },
          { data: 'subtype' },
          { data: 'vehicle' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'opened_by' },
          { data: 'status' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "All of the ERTs that you created have been closed."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#open_authored_erts-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_watch_list_table() {
      $('#data-table-watch_list').DataTable({
        ajax: '/manager/erts/get_ert_watch_list',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'placeholder',
            render: function (data, type) {
              return '';
            }
          },
          {
            data: 'ert',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/erts/' + data.ert_id + '" >' + data.ert_number + '</a>';
            }
          },
          { data: 'title' },
          { data: 'type' },
          { data: 'subtype' },
          { data: 'vehicle' },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'opened_by' },
          { data: 'status' },
          {
            data: 'comment',
            render: function (data, type) {
              var the_comment = "No Comments"
              if (!!data.member) {
                the_comment = data.member + ' - ' + data.comment_date + ' <br> ' + data.the_comment;
              }
              return the_comment;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no ERTs in your watchlist."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#ert_watch_list-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_ert_category(anCatId) {
      //window.common.debug( window.erts.ert_cat_items )
      const laClickedElem = anCatId;
      let lnItemCount = 0;
      $('#cat_items_tbody').empty();
      for (let element of Array.from(window.erts.ert_cat_items)) {
        // Only add the ERT Items if it belongs to the selected ERT Type
        if (laClickedElem === element.ert_category_id) {
          const lsItemId = '<input type="hidden" id="ert_item_id' + lnItemCount + '" name="ert_item_id' + lnItemCount + '" value="' + element._id + '"';
          const lsItem = "<td>" + element.name + lsItemId + "</td>";
          const lsQuantity = '<td><input id="ert_item' + lnItemCount + '" name="ert_item' + lnItemCount + '" size="5" type="text"></td>';
          $('#cat_items_tbody').append('<tr>' + lsItem + lsQuantity + '</tr>');
          lnItemCount = lnItemCount + 1;
        }
      }

      return $('#ert_cat_item_count').prop('value', lnItemCount);
    }
  };
  Erts.initClass();
  return Erts;
})();
//window.common.debug( lnItemCount )
//########################################################################################################

//########################################################################################################
window.erts = new Erts;