/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class CrewScheduleAvailability {

  //########################################################################################################
  // Class variables

  //########################################################################################################
  // always called
  init() {
    return window.crew_schedule_availability.row_count = 0;
  }
  //window.common.debug('Init called from window.crew_schedule_availability')
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/index
  index() {
    window.crew_schedule_availability.index_setup_all_pending_requests_table();
    window.crew_schedule_availability.index_setup_my_pending_requests_table();
    window.crew_schedule_availability.index_setup_my_processed_requests_table();
    window.crew_schedule_availability.index_setup_my_availability_table();
  }
  //########################################################################################################

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
        },
        {
          intro: '<b>Shift Availability </b> <br> This page is used to enter when members are ' +
            'Available or need Time Off.  Admins will use this page to disposition Time Off Requests.'
        },
        {
          element: '#availability_entry_div',
          intro: '<b>Availability Entry </b> <br> Click here to create an Availability Entry.',
          position: 'right'
        },
        {
          element: '#time_off_request_div',
          intro: '<b>Time Off Request </b> <br> Click here to submit a Time Off Request.',
          position: 'right'
        },
        {
          element: '#all_pending_requests-tab',
          intro: '<b>All Pending Time Off</b> <br> Crew Scheduler Admins will have access to this tab and  ' +
            'this is where Time Off Requests for all users are managed. ',
          position: 'bottom'
        },
        {
          element: '#my_pending_requests-tab',
          intro: '<b>My Pending Time Off</b> <br> This table contains your pending Time Off Requests.',
          position: 'bottom'
        },
        {
          element: '#my_processed_requests-tab',
          intro: '<b>My Processed Time Off Requests</b> <br> This table contains your processed Time Off Requests.',
          position: 'bottom'
        },
        {
          element: '#my_availability-tab',
          intro: '<b>My Availability</b> <br> This table contains your Availability Entries.',
          position: 'bottom'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  new() { }
  //window.common.debug( "New" )
  //########################################################################################################

  //########################################################################################################
  // /manager/erts/new
  edit() { }
  //window.common.debug( "Edit" )
  //########################################################################################################

  //########################################################################################################
  index_setup_all_pending_requests_table() {
    var all_pending_requests_table = $('#data-table-all_pending_requests').DataTable({
      ajax: '/manager/crew_schedule_time_off_requests/get_all_pending_requests',
      dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      columns: [
        { data: 'the_member' },
        { data: 'request_title' },
        { data: 'hours_paid' },
        {
          data: {
            _: "time_off_date.display",
            sort: "time_off_date.timestamp"
          }
        },
        {
          data: 'actions',
          render: function (data, type) {
            var the_action = ""
            the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_time_off_requests/' + data.the_time_off_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
            return the_action;
          }
        }
      ],
      fixedHeader: true,
      language: {
        emptyTable: "You do not have any Pending Requests."
      },
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-12" style="white-space: normal;">' +
                '<span>' + col.data + '</span> ' +
                '</div> </div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      stateSave: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      all_pending_requests_table.destroy();
    });
  }
  //########################################################################################################

  //########################################################################################################
  index_setup_my_pending_requests_table() {
    var my_pending_requests_table = $('#data-table-my_pending_requests').DataTable({
      ajax: '/manager/crew_schedule_time_off_requests/get_my_time_off_requests',
      dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      columns: [
        { data: 'request_title' },
        { data: 'hours_paid' },
        {
          data: {
            _: "time_off_date.display",
            sort: "time_off_date.timestamp"
          }
        },
        {
          data: 'actions',
          render: function (data, type) {
            var the_action = ""
            the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_time_off_requests/' + data.the_time_off_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
            return the_action;
          }
        }
      ],
      fixedHeader: true,
      language: {
        emptyTable: "You do not have any up coming time off."
      },
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-12" style="white-space: normal;">' +
                '<span>' + col.data + '</span> ' +
                '</div> </div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      stateSave: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      my_pending_requests_table.destroy();
    });
  }
  //########################################################################################################

  //########################################################################################################
  index_setup_my_processed_requests_table() {
    var my_processed_requests_table = $('#data-table-my_processed_requests').DataTable({
      ajax: '/manager/crew_schedule_time_off_requests/get_my_processed_requests',
      dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      columns: [
        { data: 'request_title' },
        { data: 'status' },
        { data: 'hours_paid' },
        {
          data: {
            _: "time_off_date.display",
            sort: "time_off_date.timestamp"
          }
        }
      ],
      fixedHeader: true,
      language: {
        emptyTable: "You do not have any up coming time off."
      },
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-12" style="white-space: normal;">' +
                '<span>' + col.data + '</span> ' +
                '</div> </div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      stateSave: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      my_processed_requests_table.destroy();
    });
  }
  //########################################################################################################

  //########################################################################################################
  index_setup_my_availability_table() {
    var my_availability_table = $('#data-table-my_availability').DataTable({
      ajax: '/manager/crew_schedule_availability_entries/get_my_availability',
      dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      columns: [
        { data: 'availability' },
        {
          data: {
            _: "time_off_date.display",
            sort: "time_off_date.timestamp"
          }
        },
        {
          data: 'actions',
          render: function (data, type) {
            var the_action = ""
            the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_availability_entries/' + data.the_avail_entry_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
            return the_action;
          }
        }
      ],
      fixedHeader: true,
      language: {
        emptyTable: "You do not have any up coming Availability Entries."
      },
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<div class="col-12" style="white-space: normal;">' +
                '<span>' + col.data + '</span> ' +
                '</div> </div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      stateSave: true,
      select: true
    });

    document.addEventListener('turbolinks:load', function () {
      my_availability_table.destroy();
    });
  }
  //########################################################################################################

  //########################################################################################################
  add_schedules(aaSchedules) { }
}
//window.common.debug(window.erts.ert_subtypes[0])
//########################################################################################################

//########################################################################################################
window.crew_schedule_availability = new CrewScheduleAvailability;