/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class CrewScheduleTrades {

    //########################################################################################################
    // always called
    init() { }
    //window.common.debug('Init called from window.crew_schedule_trades')
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {
      window.crew_schedule_trades.set_up_all_pending_trades();
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Trades / Coverage Requests </b> <br> This pages is used to disposition pending ' +
              'Trade Requests or Coverage Requests.'
          },
          {
            element: '#data-table-all_pending_trades',
            intro: '<b>Trades / Coverage Requests </b> <br> Here are the requests awaiting disposition.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    set_up_all_pending_trades() {
      var all_pending_trades_table = $('#data-table-all_pending_trades').DataTable({
        ajax: '/manager/crew_schedule_trades/get_all_coverage_and_trade_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'shift' },
          {
            data: {
              _: "shift_date.display",
              sort: "shift_date.timestamp"
            }
          },
          { data: 'assignment_name' },
          { data: 'member_from' },
          { data: 'member_to' },
          { data: 'status' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_trades/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no Pending Coverage Requests."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        all_pending_trades_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() { }
    //window.common.debug( "New" )
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    edit() { }
   //window.common.debug( "Edit" )
  //########################################################################################################
  }

//########################################################################################################
window.crew_schedule_trades = new CrewScheduleTrades;