/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class ErtCategories {


  //########################################################################################################
  // always called
  init() { }
  //window.common.debug('Init called from window.ert_categories')
  //########################################################################################################


  //########################################################################################################
  // /manager/ert_categories/edit
  edit() { }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/ert_categories/index
  index() { }
  //window.common.debug( "ert_categories index" )
  //########################################################################################################

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          intro: '<b>ERT Category Help (1/3)</b> <br> ERT Categories are useful for supplies that need to be routinely replaced, ' +
            'like for example EMS or Office Supplies.'
        },
        {
          intro: '<b>ERT Category Help (2/3)</b> <br> An example ERT Category for EMS would be Bandaging, with Category ' +
            ' Items like 2x2 or 4x4 dressings.'
        },
        {
          intro: '<b>ERT Category Help (3/3)</b> <br> ERT Categories and ERT Category Items cannot be deleted, but they ' +
            ' can be disabled.'
        },
        {
          element: '#back-button',
          intro: '<b>Return to ERT Types</b> <br> Return to the full ERT Types listing page.',
          position: 'bottom'
        },
        {
          element: '#new_category',
          intro: '<b>Add New Category</b> <br> Click this button to add a new Category. ',
          position: 'bottom'
        },
        {
          element: '#category_div',
          intro: '<b>ERT Category</b> <br> Each Category will be displayed in its own section.',
          position: 'top'
        },
        {
          element: '#edit_category',
          intro: '<b>Edit Category</b> <br> You can change the Category name or enable/disable at any time.',
          position: 'top'
        },
        {
          element: '#new_category_item',
          intro: '<b>New Category Item</b> <br> You can add as many new Category Items as you find necessary. ',
          position: 'top'
        },
        {
          intro: '<b>Category Items</b> <br> ' +
            'Category Items can be renamed or enabled/discabled at any time.'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

}
//########################################################################################################
window.ert_categories = new ErtCategories;