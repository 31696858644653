/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var CalendarEvents = (function() {
  CalendarEvents = class CalendarEvents {
    static initClass() {  
      //########################################################################################################
      // Class variables
    }

    //########################################################################################################
    // always called
    init() {}
      //window.common.debug('Init called from window.calendar_events')
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/edit
    edit() {}
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {
      window.calendar_events.index_setup_pending_events_table();
      window.calendar_events.index_setup_user_pending_events_table();
      window.calendar_events.index_setup_user_originated_events_table();
      window.calendar_events.index_setup_user_rejected_events_table();
      window.calendar_events.index_setup_all_events_table();
    }
    //
    //########################################################################################################

    //########################################################################################################
    index_setup_pending_events_table() {
      var pending_events_table = $('#data-table-pending_events').DataTable({
        ajax: '/manager/calendar_events/get_pending_events',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'title' },
          { data: 'calendar' },
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'event_type' },
          { data: 'location' },
          { data: 'created_by' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_calendar_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Modify</a >';
              }
              else if (data.is_calendar_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="data" data-remote="true" data-confirm="Are you sure you want to delete this event?" href="/manager/calendar_events/' + data.the_request_id + ' rel="nofollow" style="display: inline-flex"><i class="fas fa-trash-alt fa-lg"></i> &nbsp; Delete</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Pending Events."
        },
        order: [[2, 'desc']],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        pending_events_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_user_pending_events_table() {
      var user_pending_events_table = $('#data-table-user_pending_events').DataTable({
        ajax: '/manager/calendar_events/get_user_pending_events',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'title' },
          { data: 'calendar' },
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'event_type' },
          { data: 'location' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_calendar_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Modify</a >';
              }
              else if (data.is_calendar_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="data" data-remote="true" data-confirm="Are you sure you want to delete this event?" href="/manager/calendar_events/' + data.the_request_id + ' rel="nofollow" style="display: inline-flex"><i class="fas fa-trash-alt fa-lg"></i> &nbsp; Delete</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Pending Events."
        },
        order: [[2, 'desc']],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        user_pending_events_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_user_originated_events_table() {
      var user_originated_events_table = $('#data-table-user_originated_events').DataTable({
        ajax: '/manager/calendar_events/get_user_originated_events',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'title' },
          { data: 'calendar' },
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'event_type' },
          { data: 'location' },
          { data: 'status' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_calendar_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Modify</a >';
              }
              else if (data.is_calendar_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="data" data-remote="true" data-confirm="Are you sure you want to delete this event?" href="/manager/calendar_events/' + data.the_request_id + ' rel="nofollow" style="display: inline-flex"><i class="fas fa-trash-alt fa-lg"></i> &nbsp; Delete</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Originated Events."
        },
        order: [[2, 'desc']],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        user_originated_events_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_user_rejected_events_table() {
      var user_rejected_events_table = $('#data-table-user_rejected_events').DataTable({
        ajax: '/manager/calendar_events/get_user_rejected_events',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'title' },
          { data: 'calendar' },
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'event_type' },
          { data: 'location' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_calendar_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Modify</a >';
              }
              else if (data.is_calendar_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="data" data-remote="true" data-confirm="Are you sure you want to delete this event?" href="/manager/calendar_events/' + data.the_request_id + ' rel="nofollow" style="display: inline-flex"><i class="fas fa-trash-alt fa-lg"></i> &nbsp; Delete</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Rejected Events."
        },
        order: [[2, 'desc']],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        user_rejected_events_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_all_events_table() {
      var all_events_table = $('#data-table-all_events').DataTable({
        ajax: '/manager/calendar_events/get_all_events',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'title' },
          { data: 'calendar' },
          {
            data: {
              _: "event_date.display",
              sort: "event_date.timestamp"
            }
          },
          { data: 'event_type' },
          { data: 'location' },
          { data: 'created_by' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_calendar_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/calendar_events/' + data.the_request_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Modify</a >';
              }
              else if (data.is_calendar_basic == "false") {
                the_action += '<a class="btn btn-primary" data-method="data" data-remote="true" data-confirm="Are you sure you want to delete this event?" href="/manager/calendar_events/' + data.the_request_id + ' rel="nofollow" style="display: inline-flex"><i class="fas fa-trash-alt fa-lg"></i> &nbsp; Delete</a >';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Events."
        },
        order: [[2, 'desc']],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        all_events_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_setup_signup_sheet_table(calendar_event_id) {
      var signup_sheet_table = $('#data-table-signed_up').DataTable({
        ajax: {
          url: '/manager/calendar_event_sign_ups/get_signed_up_list/',
          data: {
            id: calendar_event_id
          }
        },
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: "member",
            render: function (data, type) {
              var the_return_value = "";
              if (data.has_comments == "false") {
                the_return_value = data.name;
              } else {
                the_return_value = data.name;
                the_return_value += " <i class=\"fas fa-comment\">";
              }
              return the_return_value;
            }
         },
          {
            data: "status",
            render: function (data, type) {
              var the_return_value = "";
              switch (type) {
                case 'display':
                  if (data.raw == "false") {
                    the_return_value = "<div style=\"color: green\"><i class=\"fas fa-check\"></div>";
                  } else {
                    the_return_value = "<div style=\"color: red\"><i class=\"fas fa-times\"></div>";
                  }
                  break;
                default:
                  the_return_value = data.raw;
              }
              return the_return_value;
            }
          },
          { data: 'comment' },
          { data: 'station' },
          {
            data: {
              _: "replied_on.display",
              sort: "replied_on.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              if (data.is_admin == "true") {
                the_action += '<a class="btn btn-primary" data-method="delete" data-remote="true" href="/manager/calendar_event_sign_ups/' + data.the_sign_up_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-user-minus fa-lg"></i> &nbsp; Withdraw Member</a >';
              } else if (data.is_member_me == "true") {
                the_action += '<a class="btn btn-primary" data-method="delete" data-remote="true" href="/manager/calendar_event_sign_ups/' + data.the_sign_up_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-user-minus fa-lg"></i> &nbsp; Withdraw</a >';
              } else {
                the_action = "Actions require admin permissions"
              }
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "This event does not have anyone signed up yet."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        signup_sheet_table.destroy();
      });

      $('#signup_sheet-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() {}

    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    show() { }
    
    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#all_pending_events-tab',
            intro: '<b>All Pending Events </b> <br> Only Calendar Admins will see this tab.  This tab shows all of the' +
              ' pending events.',
            position: 'bottom'
          },
          {
            element: '#my_pending_events-tab',
            intro: '<b>My Pending Events </b> <br> This tab will show your pending events.',
            position: 'bottom'
          },
          {
            element: '#my_originated_events-tab',
            intro: '<b>My Originated Events </b> <br> This tab will show your originated events.',
            position: 'bottom'
          },
          {
            element: '#my_rejected_events-tab',
            intro: '<b>My Rejected Events </b> <br> This tab will show your rejected events.',
            position: 'bottom'
          },
          {
            element: '#all_events-tab',
            intro: '<b>All Events </b> <br> Only Calendar Admins will see this tab.  This tab shows all events.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

  };
  CalendarEvents.initClass();
  return CalendarEvents;
})();
  //
  //########################################################################################################

  //########################################################################################################
window.calendar_events = new CalendarEvents;