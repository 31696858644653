/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class MaintCheckSheets {

    //########################################################################################################
    // Class variables

    //########################################################################################################
    // always called
    init() {
      //window.common.debug('Init called from window.main_check_sheets')
      return window.maint_check_sheets.set_up();
    }
    //########################################################################################################

    //########################################################################################################
    index() {
      
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#awaiting_completion_div',
            intro: '<b>Check Sheets Awaiting Completion </b> <br> This is a list of the Check Sheets that are assigned ' +
              'to your facility and that are awaiting completion.',
            position: 'top'
          },
          {
            element: '#check_access_approval_div',
            intro: '<b>Check Sheets Awaiting Approval </b> <br> Only Member\'s with Approval access will see this list. ' +
              'This list shows Check Sheets that need to be Approved for completion.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    check_sheets_overview() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>Check Sheets Help</b> <br> The Check Sheets app is used to transition your paper check sheets into ' +
              'electronic check sheets.'
          },
          {
            intro: '<b>Check Sheets Navigation </b> <br> The Check Sheets Navigation links will ' +
              'vary from member to member due to each member\'s account settings. ' +
              'Member accounts can even be set to not even show the Check Sheets app.'
          },
          {
            intro: '<b>Check Sheets Dashboard</b> <br> Any member with any amount of Check Sheets ' +
              'access will see the Check Sheets Dashboard.'
          },
          {
            intro: '<b>Active Check Sheets</b> <br> This page includes a listing of all active Check Sheets.'
          },
          {
            intro: '<b>Manage Check Sheets</b> <br> This page is used to edit Check Sheets.'
          },
          {
            intro: '<b>Search</b> <br> This page provides the capabilities to perform advanced Check Sheets searches.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    admin_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#new_check_sheet_button',
            intro: '<b>New Check Sheet </b> <br> Click this button to create a new Check Sheet.',
            position: 'top'
          },
          {
            element: '#data-table-maint_check_sheets',
            intro: '<b>Check Sheets </b> <br> This is a list of all Check Sheets.',
            position: 'top'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    show_all_check_sheets_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#data-table-maint_check_sheets',
            intro: '<b>Active Check Sheets </b> <br> This is a list of all active Check Sheets.',
            position: 'top'
          },
          {
            intro: '<b>New Check Sheet</b> <br> Visit Manage Check Sheets to create a new Check Sheet (requires Admin permission).'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    new() {
      $('#maint_check_sheet_vehicle_id').select2();
      $('#maint_check_sheet_member_assign_to_id').select2();
    }
    //########################################################################################################

    //########################################################################################################
    show() {
      window.maint_check_sheets.show_setup_check_sheet_history_table();
    }
    //########################################################################################################

    //########################################################################################################
    show_setup_check_sheet_history_table() {
      var url = window.location.pathname;
      var id = url.substring(url.lastIndexOf('/') + 1);
      var the_path = '/manager/maint_check_sheets/get_all_check_sheet_logs'

      var check_sheet_logs_table = $('#data-table-maint_check_sheet_logs').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: {
              _: "due_date.display",
              sort: "due_date.timestamp"
            }
          },
          { data: 'assigned_to' },
          {
            data: {
              _: "completed_on.display",
              sort: "completed_on.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheet_logs/' + data.the_log_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any active Check Sheets."
        },
        order: [[0, "desc"]],
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        check_sheet_logs_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#information_div',
            intro: '<b>Check Sheet Information </b> <br> Here is the Check Sheet Information.  A warning message ' +
              'will be displayed if the Check Sheet is inactive or if the Check Sheet doesn\'t have any tasks.',
            position: 'right'
          },
          {
            element: '#functions_div',
            intro: '<b>Check Sheet Functions </b> <br> The list of functions will be determined by your ' +
              'level of access to the Check Sheets app.',
            position: 'left'
          },
          {
            element: '#check_sheet_tasks-tab',
            intro: '<b>Check Sheet Tasks </b> <br> This is a list of your Check Sheet Tasks.',
            position: 'bottom'
          },
          {
            element: '#check_sheet_history-tab',
            intro: '<b>Check Sheet History </b> <br> This is a table listing of your completed or in process Check Sheets.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    add_tasks_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#information_div',
            intro: '<b>Check Sheet Information </b> <br> Here is the Check Sheet Information.  A warning message ' +
              'will be displayed if the Check Sheet is inactive or if the Check Sheet doesn\'t have any tasks.',
            position: 'right'
          },
          {
            element: '#functions_div',
            intro: '<b>Check Sheet Functions </b> <br> The list of functions will be determined by your ' +
              'level of access to the Check Sheets app.',
            position: 'left'
          },
          {
            element: '#tasks_div',
            intro: '<b>Check Sheet Tasks </b> <br> This section includes a tab for each Group.',
            position: 'bottom'
          },
          {
            element: '#tasks_div',
            intro: '<b>Check Sheet Tasks </b> <br> Each tab has a button to Add Tasks, Edit Heading, and Delete the Group.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    edit() {
      $('#maint_check_sheet_vehicle_id').select2();
      $('#maint_check_sheet_member_assign_to_id').select2();
    }
    //    $("#data-table-questions").dataTable({
    //    "bPaginate": false,
    //    "bFilter": false,
    //    "bInfo": false
    //    })
    //    $("#data-table-links").dataTable({
    //    "bPaginate": false,
    //    "bFilter": false,
    //    "bInfo": false
    //    })
    //########################################################################################################

    //########################################################################################################
    edit_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>New/Edit Check Sheet </b> <br> All fields in red are required to be entered.',
          },
          {
            element: '#title_div',
            intro: '<b>Title </b> <br> This is the title for your Check Sheet.',
            position: 'left'
          },
          {
            element: '#generate_id',
            intro: '<b>Check Sheet Generation </b> <br> You can have Check Sheets that can be created Manually when ' +
              'you need them or you can have iFire create them Automatically based on a schedule that you define.',
            position: 'bottom'
          },
          {
            element: '#created_for_div',
            intro: '<b>Create Check Sheet For </b> <br> Check Sheets can only be created for Vehicles.',
            position: 'bottom'
          },
          {
            element: '#created_for_alert_div',
            intro: '<b>Create Check Sheet For </b> <br> There are no plans to further develop the Check Sheets app.',
            position: 'bottom'
          },
          {
            element: '#vehicle_div',
            intro: '<b>Vehicle </b> <br> The Vehicle that this Check Sheets will be written for.',
            position: 'bottom'
          },
          {
            element: '#member_assigned_to_div',
            intro: '<b>Assigned To Member </b> <br> The Member that will be responsible for this Check Sheet.  Note 1: ' +
              'this is the member that will be expected to complete the Check Sheet.',
            position: 'bottom'
          },
          {
            element: '#member_assigned_to_div',
            intro: '<b>Assigned To Member </b> <br> Note 2: Any member with Complete Tasks permissions will be able to ' +
              'complete tasks for this Check Sheet.',
            position: 'bottom'
          },
          {
            element: '#active_div',
            intro: '<b>Active </b> <br> You can enable or disable a Check Sheet at any time.',
            position: 'bottom'
          },
          {
            element: '#repeating_div',
            intro: '<b>Repeat </b> <br> This is the frequency for how often the Check Sheet will be repeated.',
            position: 'bottom'
          },
          {
            element: '#description_div',
            intro: '<b>Description </b> <br> The Check Sheet description.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    create_tasks_table(anHeaderId) {
     //create_tasks_table() {
     var the_path = '/manager/maint_check_sheets/tasks_list/'

      var table_name = '#data-table-header_' + anHeaderId;
      var the_table = $(table_name).DataTable({
        ajax: {
          url: the_path,
          data: {the_header: anHeaderId}
        },
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false,
        "ordering": false,
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          {
            data: 'task',
            render: function (data, type) {
              var the_action = data.task
              if (data.inventory != 0) {
                the_action = "<span title=\"Inventory Item\" class=\"badge badge-primary\"><i class=\"fas fa-list\"></i></span> " + data.task;
              }
              if (data.qty > 1) {
                the_action += " (" + data.qty + ")"
              }
              return the_action;
            }
          },
          {
            data: 'function',
            render: function (data, type) {
              var the_action = ""
              // the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_time_off_requests/' + data.the_time_off_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              the_action += '<a data-target="#modal-form" data-toggle="modal" class="btn btn-mini btn-primary" data-remote="true" href="/manager/maint_check_sheet_tasks/' + data.id + '/edit">'+
                '<i class="fas fa-edit fa-lg" aria-hidden="true"></i> Edit </a> ';
              the_action += '&nbsp;&nbsp;';
              the_action += '<a data-target="#modal-form" data-toggle="modal" class="btn btn-mini btn-primary" data-remote="true" href="/manager/maint_check_sheet_tasks/' + data.id + '/move">'+
                '<i class="fas fa-people-carry fa-lg" aria-hidden="true"></i> Move Task </a> ';
              the_action += '&nbsp;&nbsp;';
              the_action += '<a class="btn btn-mini btn-danger" data-confirm="Are you sure you want to delete this?" data-remote="true" rel="nofollow" data-method="delete" href="/manager/maint_check_sheet_tasks/' + data.id + '">' +
                '<i class="fas fa-trash-alt fa-lg" aria-hidden="true"></i> Delete Task </a> ';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no tasks for this grouping."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        rowReorder: true,
        select: true
      });
    
      // $(table_name).on('shown.bs.tab', function (e) {
      //   $($.fn.dataTable.tables(true)).DataTable()
      //     .columns.adjust()
      //     .responsive.recalc();
      // });

      the_table.on('row-reordered', function (e, diff, edit) {
        var the_path = '/manager/maint_check_sheets/update_tasks_list/';
        var from_index = diff[0].oldPosition;
        var to_index = diff[0].newPosition; 
        var the_data = the_table.data();
        
        //console.log(the_data[from_index]);
        //console.log(the_data[from_index].function.id);

        var the_task_id = 0;
        var data = new Array();

        for (var i = 0, ien = diff.length; i < ien; i++) {
          from_index = diff[i].oldPosition;
          to_index = diff[i].newPosition; 
          the_task_id = the_data[from_index].function.id;

          // console.log("diff[" + i + "] ");
          // console.log(the_data[from_index].task);
          // console.log(the_data[from_index].function.id);
          // console.log(diff[i]);

          //$.get(the_path, { from_index: from_index, to_index: to_index, the_task_id: the_task_id })
          var the_change = { "from_index": from_index, "to_index": to_index, "the_task_id": the_task_id };
          data[i] = the_change ;
        }

        $.post(the_path, { data })

        //the_table.ajax.reload();
      });
    }
    //########################################################################################################

    //########################################################################################################
    manage_headings_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#information_div',
            intro: '<b>Check Sheet Information </b> <br> Here is the information for this Check Sheet.',
            position: 'left'
          },
          {
            element: '#return_link',
            intro: '<b>Return to Check Sheet </b> <br> Click this button to return to the previous page. ',
            position: 'bottom'
          },
          {
            element: '#add_task_header_link',
            intro: '<b>Add New Task Heading </b> <br> Click this button to start the process for manually adding ' +
              ' a new Task Heading.',
            position: 'bottom'
          },
          {
            element: '#create_heading_from_all_comparments_link',
            intro: '<b>Copy all Vehicle Compartments </b> <br> Click this button to create a Heading for all of ' +
              'the Vehicle Compartments.  Note: this function will not create duplicate Headings.',
            position: 'bottom'
          },
          {
            element: '#the_headings_table_div',
            intro: '<b>Check Sheet Headings </b> <br> Here are the Check Sheet Headings.  From here you can ' +
              'edit the Heading names or delete them.  Note that all of the Sub-Tasks will also be deleted if you ' +
              'delete the Heading',
            position: 'bottom'
          },
          {
            element: '#the_vehicle_compartments_div',
            intro: '<b>Vehicle Compartments (1 of 2) </b> <br> This is provided for reference.  A Compartment will have a  ' +
              'green background if it already exists.  Otherwise a button will be provided to create the a Heading ' +
              'for the Compartment.',
            position: 'bottom'
          },
          {
            element: '#the_vehicle_compartments_div',
            intro: '<b>Vehicle Compartments (2 of 2) </b> <br> Having a Heading linked to a compartment will allow you to automatically ' +
              'create Sub-Tasks for this compartment based on the inventory that has been identified for each compartment.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }

    //########################################################################################################
    settings() {}
    //    $("#data-table-manage_courses").dataTable({
    //    "bPaginate": true,
    //    "bFilter": true,
    //    "bInfo": true
    //    })
    //########################################################################################################

    //########################################################################################################
    set_up() {
      $("#initial_due_date").daterangepicker({
        singleDatePicker: true,
        autoApply: true,
        drops: "down",
        locale: {
          format: 'M/DD/YYYY'
        }
      });
      $("#end_date").daterangepicker({
        singleDatePicker: true,
        autoApply: true,
        drops: "up",
        locale: {
          format: 'M/DD/YYYY'
        }
      });

      $("#frequency").select2({
        minimumResultsForSearch: Infinity
      });
      $("#daily_interval").select2({
        minimumResultsForSearch: Infinity
      });
      $("#end_status").select2({
        minimumResultsForSearch: Infinity
      });
      $("#weekly_interval").select2({
        minimumResultsForSearch: Infinity
      });
      $("#weekly_day_type").select2({
        minimumResultsForSearch: Infinity
      });
      $("#type_of_monthly").select2({
        minimumResultsForSearch: Infinity
      });
      $("#day_of_the_month").select2({
        minimumResultsForSearch: Infinity
      });
      $("#monthly_interval").select2({
        minimumResultsForSearch: Infinity
      });
      $("#yearly_interval").select2({
        minimumResultsForSearch: Infinity
      });

      $('#frequency').on('change', this.configure_for_recurrence);
      $('#type_of_monthly').on('change', this.configure_for_monthly);
      $('#end_status').on('change', this.configure_for_daily_end_status);

      window.maint_check_sheets.configure_for_recurrence();
      return window.maint_check_sheets.configure_for_monthly();
    }
    //########################################################################################################

    //########################################################################################################
    configure_for_recurrence() {
      if ($("#frequency").val() === "none") {
        $("#initial_due_date_div").hide();
        $("#daily").hide();
        $("#weekly").hide();
        $("#monthly").hide();
        $("#yearly").hide();
        $("#end_status_div").hide();
        $("#occurrence_count_div").hide();
        $("#end_date_div").hide();
        $("#initial_due_date_label").addClass("form-normal");
        return $("#initial_due_date_label").removeClass("form-required");

      } else if ($("#frequency").val() === "daily") {
        $("#initial_due_date_div").show();
        $("#daily").show();
        $("#weekly").hide();
        $("#monthly").hide();
        $("#yearly").hide();
        $("#end_status_div").show();
        $("#initial_due_date_label").removeClass("form-normal");
        $("#initial_due_date_label").addClass("form-required");
        return window.maint_check_sheets.configure_for_daily_end_status();

      } else if ($("#frequency").val() === "weekly") {
        $("#initial_due_date_div").show();
        $("#daily").hide();
        $("#weekly").show();
        $("#monthly").hide();
        $("#yearly").hide();
        $("#end_status_div").show();
        $("#initial_due_date_label").addClass("form-required");
        $("#initial_due_date_label").removeClass("form-normal");
        return window.maint_check_sheets.configure_for_daily_end_status();

      } else if ($("#frequency").val() === "monthly") {
        $("#initial_due_date_div").show();
        $("#daily").hide();
        $("#weekly").hide();
        $("#monthly").show();
        $("#yearly").hide();
        $("#end_status_div").show();
        $("#initial_due_date_label").addClass("form-required");
        $("#initial_due_date_label").removeClass("form-normal");
        return window.maint_check_sheets.configure_for_daily_end_status();

      } else if ($("#frequency").val() === "yearly") {
        $("#initial_due_date_div").show();
        $("#daily").hide();
        $("#weekly").hide();
        $("#monthly").hide();
        $("#yearly").show();
        $("#end_status_div").show();
        $("#initial_due_date_label").addClass("form-required");
        $("#initial_due_date_label").removeClass("form-normal");
        return window.maint_check_sheets.configure_for_daily_end_status();
      }
    }
    //########################################################################################################

    //########################################################################################################
    configure_for_daily_end_status() {
      if ($("#end_status").val() === "never") {
        $("#occurrence_count_div").hide();
        return $("#end_date_div").hide();

      } else if ($("#end_status").val() === "count") {
        $("#occurrence_count_div").show();
        return $("#end_date_div").hide();

      } else if ($("#end_status").val() === "until") {
        $("#occurrence_count_div").hide();
        return $("#end_date_div").show();
      }
    }
    //########################################################################################################

    //########################################################################################################
    configure_for_monthly() {
      //window.common.debug( "configure_for_monthly" )

      if ($("#type_of_monthly").val() === "day_of_month") {
        $("#day_of_the_month_div").show();
        $("#week_of_the_month_div").hide();
        return $("#day_of_the_week_div").hide();
      } else if ($("#type_of_monthly").val() === "day_of_week") {
        $("#day_of_the_month_div").hide();
        $("#week_of_the_month_div").show();
        return $("#day_of_the_week_div").show();
      }
    }
  }
  //########################################################################################################

//########################################################################################################
window.maint_check_sheets = new MaintCheckSheets;