// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// ***** START: ADDED BY KisoThemes *****
window.$ = window.jQuery = require("jquery")
// ***** END: ADDED BY KisoThemes *****

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require("jquery")

import $ from 'jquery';
global.$ = jQuery;

import 'select2'
import 'select2/dist/css/select2.css'

require('intro.js')
import 'intro.js/introjs.css'

// const images = require.context('../images', true)
require("datatables.net")
//require('datatables.net-bs4')
//require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require("datatables.net-buttons")
require("datatables.net-buttons/js/buttons.html5.js")
require("datatables.net-buttons/js/buttons.flash.js")
require("datatables.net-buttons/js/buttons.print.js")
require("datatables.net-buttons-dt")
require('datatables.net-dt/css/jquery.dataTables.css')
require("datatables.net-fixedheader")
require("datatables.net-fixedheader-dt")
require("datatables.net-responsive")
require("datatables.net-responsive-dt")
require("datatables.net-rowreorder")
require('datatables.net-select-dt/css/select.dataTables.css')

//import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import 'datatables.net-dt/css/jquery.dataTables.css'
import 'datatables.net-buttons-dt/css/buttons.dataTables.css'
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css'
import 'datatables.net-responsive-dt/css/responsive.dataTables.css'
import 'datatables.net-rowreorder-dt/css/rowReorder.dataTables.css'
import 'datatables.net-select-dt/css/select.dataTables.css'

// ***** START: ADDED BY KisoThemes *****
require("bootstrap")
require("metismenu/dist/metisMenu")
require("jquery-slimscroll")
require('bootstrap-switch')
require('chartjs')
require('jquery-countdown')
require('jquery-countto')
require('cd-easypiechart')
require('fastclick')
require('object-fit-images')
require('flot/source/jquery.canvaswrapper');
require('flot/source/jquery.flot');
require('gmaps.core')
require('jasny-bootstrap')
require('jvectormap')
require('jquery-parallax.js')
require('code-prettify')
require('prismjs')
require('sparklines')
// ***** END: ADDED BY KisoThemes *****


function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('legacy/', true, /\.js$/));
requireAll(require.context('per-page/', true, /\.js$/));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("kiso_themes")


$(function () {
  try {
    tinyMCE.init({
      menubar: false,
      theme: "modern",
      plugins: [
        "advlist autolink lists link image charmap print preview hr anchor pagebreak",
        "searchreplace visualblocks visualchars code fullscreen",
        "insertdatetime media nonbreaking save table contextmenu directionality",
        "emoticons template paste textcolor"
      ],
      toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      toolbar2: "forecolor backcolor emoticons code table",
      image_advtab: true,
      skin: 'light',
      file_browser_callback: window.common.browse_media_center
    });
  } catch (e) {
    window.common.debug(e);
  }
  window.common.init();
  setupControllerActions();
  setupiFire();
  $(document).off('page:done').on('page:done', wlPageDone);
  document.addEventListener('turbolinks:load', setupControllerActions);
});

(function ($) {
  $.fn.loadRemote = function () {
    return this.each(function () {
      var el = $(this);
      var url = el.data('remote-source');
      var params = el.data('remote-params');
      if (url) {
        params = (params) ? '?' + params : '';
        resource = url + params;
        el.load(resource);
      }
    });
  };
})(jQuery);


window.addEventListener('DOMContentLoaded', () => {
  $('.js-states').select2()
})


function setupiFire() {
  $(document).on('change', '[data-submits-form]', function (event) {
    $(this).closest('form').submit();
  });

}

function setupControllerActions() {
  var target = $("#controller_hook");
  var controller = target.data("controller");
  var action = target.data("action");
  console.log("setup");
  console.log(controller);
  console.log(action)
  if (typeof window[controller] == "object") {
    if (typeof window[controller]["init"] == "function") {
      window[controller]["init"]();
    }
    if (typeof window[controller][action] == "function") {
      window[controller][action]();
    }
  }
}

function wlPageDone(event, $target, status, url, data) {
  setupControllerActions();
  window.common.process_queued_alerts();
  window.common.wise_links_done();
  window.common.debug("wlPageDone");
}
