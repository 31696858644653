
import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var CrewScheduleRecurrings = (function () {
  CrewScheduleRecurrings = class CrewScheduleRecurrings {
    static initClass() {
      //########################################################################################################
      // Class variables
    }
    //########################################################################################################

    //########################################################################################################
    new() {
      // TODO
    }
    //########################################################################################################

    //########################################################################################################
    edit() {
      window.crew_schedule_recurrings.edit_setup_positions_table();
    }
    //########################################################################################################

    //########################################################################################################
    edit_setup_recurrences_table() {
      var time_off_table = $('#data-table-time_off').DataTable({
        ajax: '/manager/crew_schedule_time_off_requests/get_my_time_off_requests',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'request_title' },
          { data: 'hours_paid' },
          {
            data: {
              _: "time_off_date.display",
              sort: "time_off_date.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_time_off_requests/' + data.the_time_off_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any up coming time off."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        time_off_table.destroy();
      });
    }
    //########################################################################################################
    
    //########################################################################################################
    edit_setup_positions_table() {
      var url = window.location.pathname;
      //var id = url.substring(url.lastIndexOf('/') + 1);
      var id = $("#crew_schedule_recurring_id").val();
      //alert(id);
      var the_path = '/manager/crew_schedule_recurrings/get_positions'

      var crew_schedule_assignments_table = $('#data-table-crew_schedule_assignments').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'position' },
          {
            data: 'filled_by',
            render: function (data, type) {
              var the_text = ""
              if (data.member_name == "") {
                the_text += '<strong><FONT COLOR="red">Open</FONT></strong>'
              } else {
                the_text += data.member_name 
                // <strong><FONT COLOR="green"><%#= item.member.full_name_by_last_name %></FONT></strong>
              } 
              return the_text;
            }
          },
          {
            data: {
              _: 'first_available.display',
              sort: 'first_available.timestamp'
            }
          },
          {
            data: {
              _: 'last_available.display',
              sort: 'last_available.timestamp'
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/crew_schedule_recurring_assignments/' + data.id +
                '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit fa-lg"></i> &nbsp; Edit</a >';
              the_action += '&nbsp;&nbsp;';
              the_action += '<a class="btn btn-mini btn-danger" data-remote="true" rel="nofollow" data-method="delete" href="/manager/crew_schedule_recurring_assignments/' + data.id + '">' +
                '<i class="fas fa-trash-alt fa-lg" aria-hidden="true"></i> Delete </a> ';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "This Crew Schedule does not have any Positions."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        crew_schedule_assignments_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    setFilledBy(the_assignment_id) {
      //window.common.debug( "setFilledBy" )
      const the_element = "#position_" + the_assignment_id;
      const the_member = $(the_element).val();
      //window.common.debug( "Selected Member |" + the_member + "|   Assignment |" + the_assignment_id + "|" )

      $.post("/manager/crew_schedule_recurring_assignments/set_assignment",
        {
          assignment_id: the_assignment_id,
          member_id: the_member
        });

      return $(the_element).prop('selectedIndex', 0);
    }
    //########################################################################################################
  };
  CrewScheduleRecurrings.initClass();
  return CrewScheduleRecurrings;
})();
//########################################################################################################

//########################################################################################################
window.crew_schedule_recurrings = new CrewScheduleRecurrings;