/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var CalendarEventTypes = (function() {
  let calendar = undefined;
  CalendarEventTypes = class CalendarEventTypes {
    static initClass() {
  
    //########################################################################################################
    // Class variables
      calendar = null;
    }

    //########################################################################################################
    // always called
    init() {}
      //window.common.debug('Init called from window.calendar_event_types')
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/edit
    edit() {
      return $('#colorpicker').colorpicker();
    }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {}
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() {
      return $('#colorpicker').colorpicker();
    }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/show
    show() {}

    
    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#new_calendar_event_type_div',
            intro: '<b>New Calendar Event Type </b> <br> Click here to create a new calendar event type.',
            position: 'bottom'
          },
          {
            element: '#calendar_event_types_table',
            intro: '<b>Calendar Event Types </b> <br> This table contains your calendar event types.',
            position: 'top'
          },
          {
            intro: '<b>Calendar Event Types </b> <br> You can create as many Calendar Event Types as you wish.'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################
    
    //########################################################################################################
    new_edit_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#title_div',
            intro: '<b>Calendar Event Type Title </b> <br> Enter the name for the Calendar Event Type.',
            position: 'top'
          },
          {
            element: '#is_enabled_div',
            intro: '<b>Enabled? </b> <br> You can enable or disable Calendar Event Types at any time.  This will not ' +
              'hide events that were previously created with this Calendar Event Type.  Instead members will not be able to select this ' + 
              'Calendar Event Type for new Calendar Events.',
            position: 'top'
          },
          {
            element: '#is_hidden_div',
            intro: '<b>Hidden? </b> <br> You can set the Calendar Event Types to be hidden from the public facing calendar. ',
            position: 'top'
          },
          {
            element: '#color_picker_div',
            intro: '<b>Color </b> <br> You can choose the color for each Calendar Event Type. ',
            position: 'top'
          }
         ]
      });

      intro.start();
    }
    //########################################################################################################

  };
  CalendarEventTypes.initClass();
  return CalendarEventTypes;
})();
  //
  //########################################################################################################

//########################################################################################################
window.calendar_event_types = new CalendarEventTypes;