import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Maintenance {

    //########################################################################################################
    // Class variables

    //########################################################################################################
    // always called
    init() {
      //window.common.debug('Init called from window.maintenance')
    }
    //########################################################################################################

    //########################################################################################################
    index() {
        window.maintenance.index_setup_awaiting_completion_table();
        window.maintenance.index_setup_awaiting_approval_table();
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_awaiting_completion_table() {
      var awaiting_completion_table = $('#data-table-awaiting_completion').DataTable({
        ajax: '/manager/maint_check_sheets/get_awaiting_completion',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'sheet_title' },
          { data: 'assigned_to' },
          {
            data: {
              _: "due_date.display",
              sort: "due_date.timestamp"
            }
          },
          { data: 'completed_tasks' },
          { data: 'total_tasks' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheet_logs/' + data.the_log_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any active Check Sheets."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        awaiting_completion_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_awaiting_approval_table() {
      var awaiting_approval_table = $('#data-table-awaiting_approval').DataTable({
        ajax: '/manager/maint_check_sheets/get_awaiting_approval',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'sheet_title' },
          { data: 'assigned_to' },
          {
            data: {
              _: "due_date.display",
              sort: "due_date.timestamp"
            }
          },
          {
            data: {
              _: "date_completed.display",
              sort: "date_completed.timestamp"
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheet_logs/' + data.the_log_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any active Check Sheets."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        awaiting_approval_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_all_check_sheets() {
        window.maintenance.index_setup_all_check_sheets_table();
    }
    //########################################################################################################

    //########################################################################################################
    index_setup_all_check_sheets_table() {
      var active_sheets_table = $('#data-table-maint_check_sheets').DataTable({
        ajax: '/manager/maint_check_sheets/get_active_check_sheets',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'sheet_title' },
          { data: 'recurrence' },
          {
            data: 'is_active',
            render: function (data, type) {
                var the_action = "";
                if (data == "true" ) {
                    the_action += '<i class="fas fa-check green" aria-hidden="true"></i>';
                } else {
                    the_action += '<i class="fas fa-times red" aria-hidden="true"></i>';
                }
                return the_action;
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheets/' + data.the_sheet_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Check Sheets."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        active_sheets_table.destroy();
      });
    }
    //########################################################################################################

    //########################################################################################################
    manage_sheets() {
        window.maintenance.manage_sheets_setup_all_check_sheets_table();
    }
    //########################################################################################################

    //########################################################################################################
    manage_sheets_setup_all_check_sheets_table() {
      var active_sheets_table = $('#data-table-maint_check_sheets').DataTable({
        ajax: '/manager/maint_check_sheets/get_all_check_sheets',
        dom: "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'sheet_title' },
          { data: 'recurrence' },
          {
            data: 'is_active',
            render: function (data, type) {
                var the_action = "";
                if (type == "display") {
                    if (data == "true") {
                        the_action += '<i class="fas fa-check green" aria-hidden="true"></i>';
                    } else {
                        the_action += '<i class="fas fa-times red" aria-hidden="true"></i>';
                    }
                } else if (type == "sort") {
                    if (data == "true") {
                        the_action = 1;
                    } else {
                        the_action = 0;
                    }
                }
                return the_action;
            }
          },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheets/' + data.the_sheet_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              return the_action;
            }
          }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "You do not have any Check Sheets."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });

      document.addEventListener('turbolinks:load', function () {
        active_sheets_table.destroy();
      });
    }
    //########################################################################################################

}

//########################################################################################################
window.maintenance = new Maintenance;