/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class TrainingCourseLogs {

//########################################################################################################
// Class variables

//########################################################################################################
// always called
  init() {}
    //window.common.debug('Init called from window.training_courses')
  //########################################################################################################

  //########################################################################################################
  // /manager/training_course_logs/index
  index() {
    return $("#data-table-training_courses").dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/training_course_logs/show
  show() {
    $("#data-table-training_links").dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
    return $("#data-table-training_files").dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
}
  //########################################################################################################

//########################################################################################################
window.training_course_logs = new TrainingCourseLogs;