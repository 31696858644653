/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Albums {

  //########################################################################################################
  // always called
  init() {
    return window.common.debug('Album manager ready.');
  }
    //########################################################################################################

  index() {
    return $(".album-box").popover({ trigger: "hover" });
  }

  //new: () ->

  //########################################################################################################
  edit() {
    window.common.debug("Editing album");
    $("div.sortable").sortable({ stop: window.albums.sorted });
    return $("div.sortable").disableSelection();
  }

  sorted(event, ui) {
    window.common.debug(event);
    window.common.debug(ui.helper);
    const items = [];
    for (let item of Array.from($(".photo-container > .photo-box"))) {
      items.push($(item).attr("data-id"));
    }
    const new_order = items.join(",");
    return $.post('/manager/albums/'+$(".photo-container").attr('data-id')+'/set_order/', {order: new_order});
  }


  // TODO -- replace with watched job equivalent
  reload_photo(id) {
    const selector = `.photo-box[data-id='${id}'] img`;
    $(selector);
    return setTimeout((() => window.albums.reset_photo(selector)), 25000);
  }

  reset_photo(selector) {
    const el = $(selector);
    window.common.debug(el);
    return el.attr('src', el.attr('data-url'));
  }
  //########################################################################################################

  trigger_upload(e) {
    $("#uploader").click();
    return e.preventDefault;
  }
}

  //########################################################################################################
  //update_photo: (id, aspect_url) ->
//########################################################################################################


window.albums = new Albums;