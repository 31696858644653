/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class GuestBooks {

//########################################################################################################
// Class variables

  //########################################################################################################
  // always called
  init() {}
    //window.common.debug('Init called from window.guest_books')
  //########################################################################################################


  //########################################################################################################
  // /manager/members/index
  index() {
    return $('#data-table-guestbook').dataTable({
      "bPaginate": false,
      "bFilter": false,
      "bInfo": false
    });
  }
  //
  //########################################################################################################

  //########################################################################################################
  // /manager/members/show
  show() {}
}
    //window.common.debug( "GuestBooks show" )
  //########################################################################################################

//########################################################################################################
window.guest_books = new GuestBooks;