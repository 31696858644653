/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var iFireCommon = (function() {
  let queued_alert = undefined;
  iFireCommon = class iFireCommon {
    static initClass() {
  
      queued_alert = '';
      this.media_callback = '';
    }

    // Provides: Safe cross-browser console debugging.
    // Usage: window.common.debug(object|string)
    debug(what) {
      if (window.console && window.console.log) { return console.log(what); }
    }

    init() {
      window.common.debug('common.init()');
      $("body").delegate('button[data-navigate]', 'click', window.common.navigate);
      $('body').delegate('[data-dismiss="alert"]', 'click', window.common.hide_errors);
      $('body').delegate('[data-media]', 'click', window.common.trigger_media_callback);
      $('a[href="#nowebmail"]').click(window.common.alert_no_webmail);
      window.common.wise_links_done();
      return window.common.debug('Application initialized. Welcome to iFire.');
    }

    back() {
      window.history.back();
      return false;
    }

    hide_errors() {
      return $("#error_hook").addClass("hide");
    }

    alert_message(message) {
      return $("#alert_hook").html(message).removeClass("hide");
    }

    queue_alert_message(message) {
      return this.queued_alert = message;
    }

    process_queued_alerts() {
      if (this.queued_alert) {
        window.common.alert_message(this.queued_alert);
        return this.queued_alert = null;
      }
    }

    alert_no_webmail() {
      alert("No webmail is configured for your department.");
      return false;
    }

    js_redirect(where) {
      window.common.debug('Redirecting to ' + where);
      window.location.href = where
      return false;
    }

    navigate(e) {
      window.common.js_redirect($(this).data('navigate'));
      e.stopPropagation();
      return e.preventDefault();
    }

    // also called on document.ready from the application.js stub
    wise_links_done() {
      // bootstrap datepicker
      $(".date-picker").datepicker();
      // amsul pickadate
      $('.pickadate').pickadate({
        format: 'mm/dd/yyyy',
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true
      });
      $("div[data-autoload]").loadRemote();
      const div = $("#editor");
      if (div.length) {
        window.common.debug("Have div");
        window.common.debug(div.size);
        const language = div.data("language");
        window.common.debug(ace);
        this.editor = ace.edit("editor");
        this.editor.getSession().setUseWorker(false);
        this.editor.getSession().setMode('ace/mode/' + language);
        window.editor = this.editor;
      }
      $(".tmce").each(function() {
        tinyMCE.execCommand('mceRemoveEditor', true, this.id);
        return tinyMCE.execCommand('mceAddEditor', true, this.id);
      });
      return window.common.debug('wldone!');
    }

    code_mirror_defaults() {
      return { lineNumbers: true, tabSize: 2 };
    }

    get_media_callback() {
      return this.media_callback;
    }

    set_media_callback(value) {
      return this.media_callback = value;
    }

    browse_media_center(field_name, url, type, win) {
      // generate cache busting random number
      tinyMCE.activeEditor.windowManager.open({
        file: '/manager/media_center/browse',
        title: 'Browse',
        width: 950,
        height: 650,
        resizable: "yes",
        plugins: "media",
        inline: "yes",
        close_previous: "no"
      }, { window: win, input: field_name });
      window.common.debug("Browsing");
      window.common.debug(field_name);
      return window.common.debug(win);
    }

    trigger_media_callback(e) {
      e.stopPropagation();
      e.preventDefault();
      const el = $(this);
      const cb = window.common.get_media_callback();
      return cb(el.attr('data-id'), el.attr('data-url'), el.attr('data-aspect-thumb'), el.attr('data-square-thumb'));
    }
  };
  iFireCommon.initClass();
  return iFireCommon;
})();

window.common = new iFireCommon;

