/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class CrewScheduleRecurrences {

//########################################################################################################
// Class variables

  //########################################################################################################
  // always called
  init() {}
    //window.common.debug('Init called from window.crew_schedule_recurrences')
  //########################################################################################################

  //########################################################################################################
  // /manager/crew_schedule_recurrences/index
  index() {}
  //########################################################################################################

  //########################################################################################################
  // /manager/crew_schedule_recurrences/new
  new() {
    //window.common.debug( "crew_schedules/new" )
    // $("#frequency").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#daily_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#end_status").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#weekly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#weekly_day_type").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#type_of_monthly").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#day_of_the_month").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#monthly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#yearly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });

    $('#frequency').on('change', this.configure_for_recurrence);
    $('#type_of_monthly').on('change', this.configure_for_monthly);
    $('#end_status').on('change', this.configure_for_daily_end_status);

    window.crew_schedule_recurrences.configure_for_recurrence();
    return window.crew_schedule_recurrences.configure_for_monthly();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/crew_schedule_recurrences/new
  edit() {
    //window.common.debug( "crew_schedules/edit" )
    // $("#frequency").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#daily_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#end_status").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#weekly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#weekly_day_type").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#type_of_monthly").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#day_of_the_month").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#monthly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });
    // $("#yearly_interval").select2({
    //   minimumResultsForSearch: Infinity
    // });

    $('#frequency').on('change', this.configure_for_recurrence);
    $('#type_of_monthly').on('change', this.configure_for_monthly);
    $('#end_status').on('change', this.configure_for_daily_end_status);

    window.crew_schedule_recurrences.configure_for_recurrence();
    return window.crew_schedule_recurrences.configure_for_monthly();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/crew_schedule_recurrences/show
  show() {}
  //########################################################################################################

  //########################################################################################################
  configure_for_recurrence() {
    if ($("#frequency").val() === "none") {
      $("#daily").hide();
      $("#weekly").hide();
      $("#monthly").hide();
      $("#yearly").hide();
      $("#end_status_div").hide();
      $("#occurrence_count_div").hide();
      return $("#end_date_div").hide();

    } else if ($("#frequency").val() === "daily") {
      $("#daily").show();
      $("#weekly").hide();
      $("#monthly").hide();
      $("#yearly").hide();
      $("#end_status_div").show();
      return window.crew_schedule_recurrences.configure_for_daily_end_status();

    } else if ($("#frequency").val() === "weekly") {
      $("#daily").hide();
      $("#weekly").show();
      $("#monthly").hide();
      $("#yearly").hide();
      $("#end_status_div").show();
      return window.crew_schedule_recurrences.configure_for_daily_end_status();

    } else if ($("#frequency").val() === "monthly") {
      $("#daily").hide();
      $("#weekly").hide();
      $("#monthly").show();
      $("#yearly").hide();
      $("#end_status_div").show();
      return window.crew_schedule_recurrences.configure_for_daily_end_status();

    } else if ($("#frequency").val() === "yearly") {
      $("#daily").hide();
      $("#weekly").hide();
      $("#monthly").hide();
      $("#yearly").show();
      $("#end_status_div").show();
      return window.crew_schedule_recurrences.configure_for_daily_end_status();
    }
  }
  //########################################################################################################

  //########################################################################################################
  configure_for_daily_end_status() {
    if ($("#end_status").val() === "never") {
      $("#occurrence_count_div").hide();
      return $("#end_date_div").hide();

    } else if ($("#end_status").val() === "count") {
      $("#occurrence_count_div").show();
      return $("#end_date_div").hide();

    } else if ($("#end_status").val() === "until") {
      $("#occurrence_count_div").hide();
      return $("#end_date_div").show();
    }
  }
  //########################################################################################################

  //########################################################################################################
  configure_for_monthly() {
    //window.common.debug( "configure_for_monthly" )

    if ($("#type_of_monthly").val() === "day_of_month") {
      $("#day_of_the_month_div").show();
      $("#week_of_the_month_div").hide();
      return $("#day_of_the_week_div").hide();
    } else if ($("#type_of_monthly").val() === "day_of_week") {
      $("#day_of_the_month_div").hide();
      $("#week_of_the_month_div").show();
      return $("#day_of_the_week_div").show();
    }
  }
}
  //########################################################################################################

//########################################################################################################
window.crew_schedule_recurrences = new CrewScheduleRecurrences;