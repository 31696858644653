/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
var Pos = (function() {
  let vendors = undefined;
  let vehicles = undefined;
  let row_count = undefined;
  Pos = class Pos {
    static initClass() {
  
      //########################################################################################################
      // Class variables
      vendors = [];
      vehicles = [];
      row_count = 0;
    }

    //########################################################################################################
    // always called
    init() {
      return window.pos.row_count = 0;
    }
      //window.common.debug('Init called from window.pos')
    //########################################################################################################


    //########################################################################################################
    // /manager/erts/edit
    edit() {
      return $('#po_po_vendor_id').on('change', this.changed_vendor);
    }
    //
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/index
    index() {
      // $('#data-table-approval').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_awaiting_approval_table();

      // $('#data-table-watch').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_po_watch_list_table();

      // return $('#data-table-pos_authored').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_open_authored_po_table();
    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#spreadsheets_div',
            intro: '<b>PO Dashboard (1/2) </b> <br> The PO Dashboard provides you with with the ability to quickly look up a PO and it also has ' +
              ' lists of the POs that are tailered to each user.',
            position: 'bottom'
          },
          {
            element: '#spreadsheets_div',
            intro: '<b>PO Dashboard (2/2) </b> <br> Each PO table has the ability to export the tables data to Excel, CSV, PDF, or print.',
            position: 'bottom'
          },
          {
            element: '#po_search',
            intro: '<b>PO Quick Search</b> <br> If you know a PO number you can view it by entering it here and clicking the Submit button. ',
            position: 'bottom'
          },
          {
            element: '#awaiting_approval-tab',
            intro: '<b>PO Items Awaiting Approval</b> <br> This table contains the POs that has Items Awaiting your Approval.',
            position: 'bottom'
          },
          {
            element: '#po_watch_list-tab',
            intro: '<b>PO Watch List</b> <br> This table contains a list of the POs that you added to your watch list, regardless of the PO status.',
            position: 'bottom'
          },
          {
            element: '#open_authored_pos-tab',
            intro: '<b>Open Authored POs</b> <br> This table contains the list of open POs that you created.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    index_overview() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            intro: '<b>PO Help</b> <br> POMS is short for <b>P</b>urchase <b>O</b>rder <b>M</b>anagement <b>S</b>ystem.  POMS is used to" ' +
              ' manage your agency\'s purchase orders.'
          },
          {
            intro: '<b>POMS Navigation </b> <br> The POMS Navigation links will vary from member to member due to each member\'s account settings. ' +
              'Member accounts can even be set to not even show POMS.'
          },
          {
            intro: '<b>POMS Dashboard</b> <br> Any member with any amount of POMS access will see the POMS Dashboard.'
          },
          {
            intro: '<b>New PO</b> <br> This page is used to create new POs. ' +
              'This capability requires account permission to access.  If you do not see the link then you do not have permission to create new POs.'
          },
          {
            intro: '<b>PO Accounts</b> <br> '
          },
          {
            intro: '<b>PO Process Flow</b> <br> '
          },
          {
            intro: '<b>PO Vendors</b> <br> '
          },
          {
            intro: '<b>View Closed POs</b> <br> '
          },
          {
            intro: '<b>View Open POs</b> <br> '
          },
          {
            intro: '<b>View Recurring POs</b> <br> '
          },
          {
            intro: '<b>Settings</b> <br> '
          }
        ]
      });

      intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    set_awaiting_approval_table() {
      //-----------------------------------------------------------------
      $('#data-table-approval').DataTable({
        ajax: '/manager/pos/get_pos_awaiting_approval',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'item_description' },
          { data: 'total_item_cost' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no POs Awaiting Your Approval."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#awaiting_approval-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_po_watch_list_table() {
      //-----------------------------------------------------------------
      $('#data-table-watch').DataTable({
        ajax: '/manager/pos/get_po_watch_list',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no POs in your Watch List."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#po_watch_list-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_open_authored_po_table() {
      //-----------------------------------------------------------------
      $('#data-table-pos_authored').DataTable({
        ajax: '/manager/pos/get_open_authored_pos',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no POs in your Watch List."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#po_watch_list-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    view_closed_pos() {
      return $('#data-table-closed_pos').dataTable({
        "bPaginate": true,
        "bFilter": true,
        "bInfo": false
      });
    }
    //########################################################################################################

    //########################################################################################################
    view_open_pos() {
      // $('#data-table-open_pos').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_open_table();

      // $('#data-table-pos_awaiting_approval').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_approval_table();

      // $('#data-table-pos_awaiting_invoice').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_invoice_table();

      // return $('#data-table-pos_awaiting_payment').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_payment_table();
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_open_table() {
      //-----------------------------------------------------------------
      var is_recurring = $("#is_recurring").val();
      var the_path = '/manager/pos/get_assigned_open_pos'

      $('#data-table-open_pos').DataTable({
        ajax: {
          url: the_path,
          data: { "is_recurring": is_recurring }
        },
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no Open POs assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#pos_awaiting_payment-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_awaiting_approval_table() {
      //-----------------------------------------------------------------
      var is_recurring = $("#is_recurring").val();
      var the_path = '/manager/pos/get_assigned_awaiting_approval'

      $('#data-table-pos_awaiting_approval').DataTable({
        ajax: {
          url: the_path,
          data: { "is_recurring": is_recurring }
        },
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' },
          { data: 'approvers' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no Open POs assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#pos_awaiting_payment-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_awaiting_invoice_table() {
      //-----------------------------------------------------------------
      var is_recurring = $("#is_recurring").val();
      var the_path = '/manager/pos/get_assigned_awaiting_invoice'

      $('#data-table-pos_awaiting_invoice').DataTable({
        ajax: {
          url: the_path,
          data: { "is_recurring": is_recurring }
        },
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no Open POs assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#pos_awaiting_payment-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    set_assigned_awaiting_payment_table() {
      //-----------------------------------------------------------------
      var is_recurring = $("#is_recurring").val();
      var the_path = '/manager/pos/get_assigned_awaiting_payment'

      $('#data-table-pos_awaiting_payment').DataTable({
        ajax: {
          url: the_path,
          data: { "is_recurring": is_recurring }
        },
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
          {
            data: 'po',
            order: 'asc',
            render: function (data, type) {
              return '<a data-push="true" href="/manager/pos/' + data.po_id + '" >' + data.po_number + '</a>';
            }
          },
          {
            data: {
              _: "opened_on.display",
              sort: "opened_on.timestamp"
            }
          },
          { data: 'payable_to' },
          { data: 'total_cost' },
          { data: 'status' }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no Open POs assigned to you."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#pos_awaiting_payment-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    load_pos() {
      return $('#data-table-pos').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
    }

    //########################################################################################################

    //########################################################################################################
    view_recurring_pos() {
      // $('#data-table-open_pos').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_open_table();

      // $('#data-table-pos_awaiting_approval').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_approval_table();

      // $('#data-table-pos_awaiting_invoice').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_invoice_table();

      // return $('#data-table-pos_awaiting_payment').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_assigned_awaiting_payment_table();
    }
    //########################################################################################################

    //########################################################################################################
    // /manager/erts/new
    new() {
      //window.common.debug( "po new" )
      $("#po_po_vendor_id").select2();
      return $('#po_po_vendor_id').on('change', this.changed_vendor);
    }
    //########################################################################################################


    //########################################################################################################
    // /manager/erts/show
    show() {
      $('#data-table-po_viewed_by').dataTable({
        "bPaginate": false,
        "bFilter": false,
        "bInfo": false
      });
      // return $('#data-table-po_items').dataTable({
      //   "bPaginate": false,
      //   "bFilter": false,
      //   "bInfo": false
      // });
      window.pos.set_po_items_table();
    }
    //
    //########################################################################################################

    //########################################################################################################
    set_po_items_table() {
      //-----------------------------------------------------------------
      var id = $("#po_id").val();
      var the_path = '/manager/pos/get_po_items'

      $('#data-table-po_items').DataTable({
        ajax: {
          url: the_path,
          data: { "id": id }
        },
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        columns: [
          { data: 'vehicle' },
          { data: 'decription' },
          { data: 'quantity' },
          { data: 'cost_ea' },
          { data: 'ext_cost' },
          { data: 'ert_num' },
          { data: 'account_name' },
          {
            data: {
              _: "approved_on.display",
              sort: "approved_on.timestamp"
            }
          },
          { data: 'approved_by' },
          {
            data: 'actions',
            render: function (data, type) {
              var the_action = ""
              // the_action += '<a data-push="true" class="btn btn-primary" href="/manager/maint_check_sheet_logs/' + data.the_log_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-clipboard-check fa-lg"></i> &nbsp; Open Check Sheet</a >';
              if (data.buttons_available == "true")
              {
                if (data.can_edit == "true") {
                  the_action += '<div style="display: inline-block">';
                  the_action += '<a data-target="#modal-form" data-toggle="modal" class="btn btn-mini btn-primary" href="/manager/po_items/' + data.po_item_id + '/edit" data-remote="true" data-method="get"><i class="fa fa-pencil-square-o"></i> &nbsp; Edit</a >';
                  the_action += '</div> ';
                }

                if (data.can_approve == "true") {
                  the_action += '<div style="display: inline-block">';
                  the_action += '<a class="btn btn-mini btn-success" href="/manager/po_items/' + data.po_item_id + '?update_action=approve_action" data-remote="true" data-method="put"><i class="fa fa-check"></i> &nbsp; Approve</a >';
                  the_action += '</div> ';
                } else if (data.can_disapprove == "true") {
                  the_action += '<div style="display: inline-block">';
                  the_action += '<a class="btn btn-mini btn-warning" href="/manager/po_items/' + data.po_item_id + '?update_action=disapprove_action" data-remote="true" data-method="put" ><i class="fa fa-times"></i> &nbsp; Disapprove</a >';
                  the_action += '</div> ';
                }

                the_action += '<div style="display: inline-block">';
                the_action += '<a class="btn btn-mini btn-danger" href="/manager/po_items/' + data.po_item_id + '" data-remote="true" data-method="delete"><i class="fa fa-trash"></i> &nbsp; Delete</a >';
                the_action += '</div> ';
              }
              return the_action;
            }
          }
        ],
        fixedHeader: false,
        language: {
          emptyTable: "There are no PO Items."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#pos_awaiting_payment-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    add_vendors(aaVendors) {
      return window.pos.vendors = aaVendors;
    }
    //window.common.debug(window.erts.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    add_vehicles(aaVehicles) {
      return window.pos.vehicles = aaVehicles;
    }
    //window.common.debug(window.erts.ert_subtypes[0])
    //########################################################################################################

    //########################################################################################################
    calculate_total_cost(event) {
      //window.common.debug("calculate_total_cost")
      const quantity  = $("#po_item_quantity").prop("value");
      const cost_each = $("#po_item_cost_each").prop("value");
      return $("#PoItemTotalItemCost").html( "$" + (quantity * cost_each) );
    }
    //########################################################################################################

    //########################################################################################################
    changed_vendor(event) {
      const laClickedElem = $(event.target).prop("selectedIndex");
      //window.common.debug( laClickedElem )
      //window.common.debug( window.pos.vendors[laClickedElem-1] )

      if (laClickedElem > 0) {
        const lrErtType = window.pos.vendors[laClickedElem-1];

        $('#vendor_name').text( lrErtType.name );
        $('#vendor_poc').text( lrErtType.poc );
        $('#vendor_address').text( lrErtType.street_address );
        $('#vendor_city').text( lrErtType.city );
        $('#vendor_state').text( lrErtType.state );
        $('#vendor_zip').text( lrErtType.zip );
        $('#vendor_phone').text( lrErtType.phone );
        $('#vendor_fax').text( lrErtType.fax );
        return $('#vendor_email').text( lrErtType.email );
      } else {
        $('#vendor_name').text( " " );
        $('#vendor_poc').text( " " );
        $('#vendor_address').text( " " );
        $('#vendor_city').text( " " );
        $('#vendor_state').text( " " );
        $('#vendor_zip').text( " " );
        $('#vendor_phone').text( " " );
        $('#vendor_fax').text( " " );
        return $('#vendor_email').text( " " );
      }
    }
  };
  Pos.initClass();
  return Pos;
})();

  //########################################################################################################

//########################################################################################################
window.pos = new Pos;