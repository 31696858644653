/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Vehicles {


  //########################################################################################################
  // always called
  init() { }
  //window.common.debug('Init called from window.vehicles')
  //########################################################################################################

  //########################################################################################################
  // /manager/vehicles/show
  show() {
    $('#data-table-vehicle_seats').DataTable({
      info: false,
      ordering: false,
      paging: false,
      searching: false
    });

    $('#data-table-vehicle_compartments').DataTable({
      info: false,
      ordering: false,
      paging: false,
      searching: false
    });
  }
  //
  //########################################################################################################

  //########################################################################################################
  show_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#edit_category',
          intro: '<b>Edit Vehicle</b> <br> Click here to Edit the Vehicle. ' +
            'Note that Vehicles cannot be deleted, but they can be enabled/disabled. ',
          position: 'bottom'
        },
        {
          element: '#vehicle_information_div',
          intro: '<b>Vehicle Information</b> <br> Here is the information for the Vehicle. ',
          position: 'bottom'
        },
        {
          element: '#vehicle_compartments_div',
          intro: '<b>Vehicle Compartments</b> <br> Here are the Compartments for the Vehicle. The order comes into ' +
            'play when you use Check Sheets.  The compartment order is followed with the sheets.',
          position: 'top'
        },
        {
          element: '#vehicle_seats_div',
          intro: '<b>Vehicle Seats</b> <br> Here are the Seats for the Vehicle. The order comes into ' +
            'play when you use Calendar Sign-up Sheets.  The compartment order is followed with the sheets.',
          position: 'top'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

  //########################################################################################################
  // /manager/vehicles/index
  index() {
    $('#data-table-vehicles').DataTable({
      ajax: {
        url: '/manager/vehicles/get_vehicles'
      },
      columns: [
        { data: 'name', },
        {
          className: "dt-center",
          data: {
            sort: 'is_enabled'
          },
          ClassName: 'dt-center',
          render: function (data, type) {
            var the_action = data.is_enabled;
            if (data.is_enabled === "true") {
              the_action = '<i class="fas fa-check"></i>';
            } else {
              the_action = '<i class="fas fa-times"></i>';
            }
            return type === "display" ? the_action : data;
          }
        },
        { data: 'facility' },
        { data: 'make' },
        {
          data: 'actions',
          render: function (data, type) {
            var the_action = '<table> <tr style="background: transparent;">';
            the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
            the_action += '<a class="btn btn-primary" data-method="get" href="/manager/vehicles/' + data.vehicle_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-cog"></i> &nbsp; Show</a >';
            the_action += '</td>';
            the_action += '</tr></table>';

            return the_action;
          }
        }
      ],
      fixedHeader: true,
      paging: false,
      responsive: {
        details: {
          renderer: function (api, rowIdx, columns) {
            var data = $.map(columns, function (col, i) {
              return col.hidden ?
                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                '</div>' +
                '<div class="row">' +
                '<span>' + col.data + '</span> ' +
                '</div> <br>' :
                '';
            }).join('');

            return data ?
              data :
              false;
          }
        }
      },
      searchPane: false,
      select: true
    });
  }

  //########################################################################################################
  index_help() {
    // window.common.debug('called index_help');
    var intro = introJs();
    intro.setOptions({
      showStepNumbers: false,
      steps: [
        {
          intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
        },
        {
          element: '#new_action',
          intro: '<b>Add a New Vehicle</b> <br> Click here to add a new Vehicle. ' +
            'Note that Vehicles cannot be deleted, but they can be disabled. ',
          position: 'bottom'
        },
        {
          element: '#data-table-vehicles',
          intro: '<b>Vehicles</b> <br> Here is a listing of your Vehicles. ',
          position: 'bottom'
        },
        {
          intro: '<b>Vehicles</b> <br> ' +
            'Vehicles can be renamed or enabled/discabled at any time.'
        }
      ]
    });

    intro.start();
  }
  //########################################################################################################

}
//########################################################################################################

//########################################################################################################
window.vehicles = new Vehicles;