import introJs from 'intro.js'
class Inventory {

    //########################################################################################################
    // Class variables

    //########################################################################################################
    // always called
    init() {
      window.common.debug('Init called from window.inventory')
      //return window.maint_check_sheets.set_up();
    }

    //########################################################################################################
    admin() {
      window.inventory.show_setup_inventory_items_table();
      window.inventory.show_setup_inventory_categories_table();
      window.inventory.show_setup_inventory_locations_table();
    }
    //########################################################################################################

    //########################################################################################################
    show_setup_inventory_items_table() {
      $('#data-table-inventory_items').DataTable({
        ajax: '/manager/inventory_items/get_inventory_items',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
            {
                data: 'item_name',
                order: 'asc',
                render: function (data, type) {
                return data.item_name;
                }
            },
            { data: 'the_category' },
            {
                data: 'is_serialized',
                render: function (data, type) {
                    var the_action = '<i class="fas fa-check fa-lg" style="color: green"></i>';
                    if (data.is_serialized == "false") {
                        the_action = '<i class="fas fa-times fa-lg" style="color: red"></i>';
                    }
                    return the_action;
                }
            },
            {
                data: 'is_enabled',
                render: function (data, type) {
                    var the_action = '<i class="fas fa-check fa-lg" style="color: green"></i>';
                    if (data.is_enabled == "false") {
                        the_action = '<i class="fas fa-times fa-lg" style="color: red"></i>';
                    }
                    return the_action;
                }
            },
            {
                data: 'actions',
                render: function (data, type) {
                var the_action = ""
                the_action += '<a data-push="true" class="btn btn-primary" href="/manager/inventory_items/' + data.item_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-edit fa-lg"></i> &nbsp; Show Item</a>';
                return the_action;
                }
            }
        ],
        columnDefs: [
            { targets: 2, className: "text-center" },
            { targets: 3, className: "text-center" }
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no Inventory Items."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#items-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_setup_inventory_categories_table() {
      $('#data-table-inventory_categories').DataTable({
        ajax: '/manager/inventory_categories/get_inventory_categories',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
            {
                data: 'item_name',
                order: 'asc',
                render: function (data, type) {
                return data.item_name;
                }
            },
            {
                data: 'is_enabled',
                render: function (data, type) {
                    var the_action = '<i class="fas fa-check fa-lg" style="color: green"></i>';
                    if (data.is_enabled == "false") {
                        the_action = '<i class="fas fa-times fa-lg" style="color: red"></i>';
                    }
                    return the_action;
                }
            },
            {
                data: 'actions',
                render: function (data, type) {
                var the_action = ""
                the_action += '<a data-push="true" class="btn btn-primary" href="/manager/inventory_categories/' + data.item_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-edit fa-lg"></i> &nbsp; Show Item</a>';
                return the_action;
                }
            }
        ],
        columnDefs: [
            { targets: 1, className: "text-center" },
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no Inventory Categories."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#categories-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_setup_inventory_locations_table() {
      $('#data-table-inventory_locations').DataTable({
        ajax: '/manager/inventory_locations/get_inventory_locations',
        dom: "<'row'<'col-sm-12 pb-3'B>>" +
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
          'excelHtml5',
          'csvHtml5',
          'pdfHtml5',
          'print'
        ],
        columns: [
            {
                data: 'item_name',
                order: 'asc',
                render: function (data, type) {
                return data.item_name;
                }
            },
            { data: 'facility' },
            {
                data: 'is_enabled',
                render: function (data, type) {
                    var the_action = '<i class="fas fa-check fa-lg" style="color: green"></i>';
                    if (data.is_enabled == "false") {
                        the_action = '<i class="fas fa-times fa-lg" style="color: red"></i>';
                    }
                    return the_action;
                }
            },
            {
                data: 'actions',
                render: function (data, type) {
                var the_action = ""
                the_action += '<a data-push="true" class="btn btn-primary" href="/manager/inventory_locations/' + data.item_id + '" rel="nofollow" style="display: inline-flex"><i class="fas fa-edit fa-lg"></i> &nbsp; Show Item</a>';
                return the_action;
                }
            }
        ],
        columnDefs: [
            { targets: 2, className: "text-center" },
        ],
        fixedHeader: true,
        language: {
          emptyTable: "There are no Inventory Locations."
        },
        responsive: {
          details: {
            renderer: function (api, rowIdx, columns) {
              var data = $.map(columns, function (col, i) {
                return col.hidden ?
                  '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                  '<span style="font-weight: bold;">' + col.title + '</span> ' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col-12" style="white-space: normal;">' +
                  '<span>' + col.data + '</span> ' +
                  '</div> </div> <br>' :
                  '';
              }).join('');

              return data ?
                data :
                false;
            }
          }
        },
        stateSave: true,
        select: true
      });
      $('#categories-tab').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    }
    //########################################################################################################

    //########################################################################################################
    show_help() {
      // window.common.debug('called index_help');
      var intro = introJs();
      intro.setOptions({
        showStepNumbers: false,
        steps: [
          {
            intro: '<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel.'
          },
          {
            element: '#information_div',
            intro: '<b>Check Sheet Information </b> <br> Here is the Check Sheet Information.  A warning message ' +
              'will be displayed if the Check Sheet is inactive or if the Check Sheet doesn\'t have any tasks.',
            position: 'right'
          },
          {
            element: '#functions_div',
            intro: '<b>Check Sheet Functions </b> <br> The list of functions will be determined by your ' +
              'level of access to the Check Sheets app.',
            position: 'left'
          },
          {
            element: '#check_sheet_tasks-tab',
            intro: '<b>Check Sheet Tasks </b> <br> This is a list of your Check Sheet Tasks.',
            position: 'bottom'
          },
          {
            element: '#check_sheet_history-tab',
            intro: '<b>Check Sheet History </b> <br> This is a table listing of your completed or in process Check Sheets.',
            position: 'bottom'
          }
        ]
      });

      intro.start();
    }
}
//########################################################################################################

//########################################################################################################
window.inventory = new Inventory;