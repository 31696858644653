import introJs from 'intro.js'

const root = typeof exports !== 'undefined' && exports !== null ? exports : this;
class Facilities {

    //########################################################################################################
    // always called
    init() {
        //window.common.debug('Init called from window.ert_category_items');
    }
    //########################################################################################################

    //########################################################################################################
    index() {
        $('#data-table-facilities').DataTable({
            ajax: {
                url: '/manager/facilities/get_facilities'
            },
            columns: [
                { data: 'facility_name', },
                {
                    className: "dt-center",
                    data: {
                        sort: 'is_enabled'
                    },
                    ClassName: 'dt-center',
                    render: function (data, type) {
                        var the_action = data.is_enabled;
                        if (data.is_enabled === "true") {
                            the_action = '<i class="fas fa-check"></i>';
                        } else {
                            the_action = '<i class="fas fa-times"></i>';
                        }
                        return type === "display" ? the_action : data;
                    }
                },
                {
                    data: 'actions',
                    render: function (data, type) {
                        var the_action = '<table> <tr style="background: transparent;">';
                        the_action += '<td style="border: 0px; background-color: transparent; padding-top: 0px; padding-bottom: 0px;">';
                        the_action += '<a class="btn btn-primary" data-method="get" data-remote="true" href="/manager/facilities/' + data.the_facility_id + '/edit" rel="nofollow" style="display: inline-flex"><i class="far fa-edit"></i> &nbsp; Edit</a >';
                        the_action += '</td>';
                        the_action += '</tr></table>';

                        return the_action;
                    }
                }
            ],
            fixedHeader: true,
            paging: false,
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<div class="row" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<span style="font-weight: bold;">' + col.title + '</span> ' +
                                '</div>' +
                                '<div class="row">' +
                                '<span>' + col.data + '</span> ' +
                                '</div> <br>' :
                                '';
                        }).join('');

                        return data ?
                            data :
                            false;
                    }
                }
            },
            searchPane: false,
            select: true
        });

    }
    //########################################################################################################

    //########################################################################################################
    index_help() {
        // window.common.debug('called index_help');
        var intro = introJs();
        intro.setOptions({
            showStepNumbers: false,
            steps: [
                {
                    intro: "<b>How to Naviagate Help</b> <br> You can use left and right arrow keys to navigate or Esc to cancel."
                },
                {
                    element: '#new_action',
                    intro: '<b>Add a New Facility</b> <br> Click here to add a new Facility. ' +
                        'Note that Facilities cannot be deleted, but they can be disabled. ',
                    position: 'bottom'
                },
                {
                    element: '#data-table-facilities',
                    intro: '<b>Facilities</b> <br> Here is a listing of your Facilities. ',
                    position: 'bottom'
                },
                {
                    intro: '<b>Facilities</b> <br> ' +
                        'Facilities can be renamed or enabled/discabled at any time.'
                }
            ]
        });

        intro.start();
    }
    //########################################################################################################

    //########################################################################################################
    new() {

    }
    //########################################################################################################

    //########################################################################################################
    edit() {

    }
    //########################################################################################################

}

//########################################################################################################
window.facilities = new Facilities;